import React, { FunctionComponent, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from 'yup';
import { Row, Col } from "react-bootstrap";
import InputField from "components/Controls/InputField";
import { Button, message, Modal, notification } from 'antd';
import { message as notificationMessage } from 'helpers/notificationhelper';
import { useTranslation } from "react-i18next";
import OTPComponent from './../OTPComponent/index';
import { login } from "../../../../api/login";
import {AUTH_ACTIONS} from 'redux/types/auth-types';
import { useDispatch } from "react-redux";
import {
    logout
} from 'api/logout';
import Cookies from "js-cookie";
import { TOKEN_NAME } from "constants/index";
import { preventNonNumericalInput, AllowNumberForMobile } from 'helpers/utils';

interface MobileAuthenticationComponentProps {

}

const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-")
    let checkIfNum;
    if (e.key !== undefined) {
        // Check if it's a "e", ".", "+" or "-"
        checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    }
    else if (e.keyCode !== undefined) {
        // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
        checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }

    return checkIfNum && e.preventDefault();
}

const MobileAuthenticationComponent: FunctionComponent<MobileAuthenticationComponentProps> = (
    props
) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showOTPComponent, setShowOTPComponent] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const Regex = new RegExp(/^(0|61)\d{9}$/);
    const { confirm } = Modal;
    const dispatch = useDispatch();

    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);

    return (
        <Formik
            initialValues={{
                mobileNumber: "",
            }}
            validationSchema={Yup.object({
                mobileNumber: Yup.string().matches(Regex, t("message.invalid_mobile_number")).required(t("message.mobile_no_required")),
            })}
            onSubmit={(values) => {
                const payload = {
                    contactNumber: values.mobileNumber.trim(),
                };
                setMobileNumber(values.mobileNumber.trim());
                message.destroy();
                notification.destroy();
                login(payload)
                    .then(e => {
                        if (!e.data.status){                           
                                confirm({
                                    title: '',
                                    content: e.data.message,
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    maskClosable: true,
                                    onOk: () => {
                                        logout(payload)
                                        .then(e => {
                                            if (e && e.status === 200) {
                                                notificationMessage.success('You have successfully logged out of other device.');
                                                dispatch({
                                                    type: AUTH_ACTIONS.REMOVE_TOKEN
                                                });
                                                Cookies.remove(TOKEN_NAME);

                                                login(payload)
                                                    .then(e => {
                                                        if (e && e.status === 200) {
                                                            setShowOTPComponent(true);
                                                        }
                                                    })
                                                    .catch(e => {
                                                        if (e.response && e.response.status === 500) {
                                                            notificationMessage.error("An error has occurred. Please try again later.")
                                                        }
                                                    });
                                            }
                                        })
                                        .catch(e => {
                                            if (e && e.status === 500) {
                                                notificationMessage.error("An error has occurred. Please try again later.")
                                            }
                                        });
                                    }
                                });
                            
                        } else {
                         setShowOTPComponent(true);
                        }
                    })
                    .catch(e => {
                       
                        if (e.response && e.response.status === 500) {
                            notificationMessage.error("An error has occurred. Please try again later.")
                        }
                        // setIsLoading(false)
                    })
                // completeAddPatientUser(payload, uniqueId)
                //   .then((response) => {
                //     message.success("Successful");

                //     setTimeout(() => {
                //       keycloak.login({
                //         redirectUri: window.location.href.replace(signupUri, ""),
                //       });
                //     }, 2000);
                //   })
                //   .catch((err) => {
                //     const response = err.response;
                //     if (response && response.data && response.status === 409) {
                //       message.error("Username already exist");
                //     } else {
                //       console.log(response);
                //       message.error("Something went wrong");
                //     }
                //   })
                //   .finally(() => setIsLoading(false));
            }}
        >
            {showOTPComponent ? <OTPComponent mobileNumber={mobileNumber} /> : <Form>
                <Row className="justify-content-center">
                    <Col xs={12} sm={4}>
                        <p className="mt-5">Log In</p>
                        <Field
                            id="mobileNumber"
                            name="mobileNumber"
                            label=""
                            placeholder={t("placeholder.enter_mobile_no")}
                            pattern="[0-9]*"
                            component={InputField}
                            autoComplete="off"
                            onKeyPress ={(e) => preventNonNumericalInput(e)}
                            onKeyUp = {(e) => AllowNumberForMobile(e)}
                            onKeyDown={(e) => formatInput(e)}
                            autoFocus={isMobile ? false : true}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center my-5">
                    <Col xs={8} sm={3}>
                        <Button
                            htmlType="submit"
                            loading={isLoading}
                            type="primary"
                            block
                        >
                            Log In
                        </Button>
                    </Col>
                </Row>
                {/* <div className="Content-Footer">
                <Row>
                    <Col>
                    <Button
                        htmlType="submit"
                        loading={isLoading}
                        type="primary"
                        block
                    >
                        Sign Up
                    </Button>
                    </Col>
                </Row>
                <div className="Sub-Footer">
                    <Row>
                    <Col>
                        Already have an account?{" "}
                        <Link to="#" onClick={() => (window.location.href = "/")}>
                        Login here
                        </Link>
                    </Col>
                    </Row>
                </div>
                </div> */}
            </Form>}
        </Formik>
    );
};

export default MobileAuthenticationComponent