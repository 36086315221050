import {
    SET_TASKS_INCOMPLETE_COUNT
} from 'redux/actions/types/tasks';

const INITIAL_STATE = {
    incompletePatientTasks: null
}

const taskReducer = (state = INITIAL_STATE, action) => {
    const {type, payload, error} = action;

    switch (type) {
        case SET_TASKS_INCOMPLETE_COUNT: {
            return {
                ...state,
                incompletePatientTasks: payload
            }
        }

        default:
            return state
    }
}

export default taskReducer;
