import {
    SET_EDITOR_UTILITIES,
    SET_FORM_SUBMISSIONS_INCOMPLETE_COUNT
} from 'redux/actions/types/formBuilder';

const INITIAL_STATE = {
    utilities: null,
    incompleteFormSubmissions: null
};

const formBuilderReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_EDITOR_UTILITIES:
            return { 
                ...state, 
                utilities: payload
            }

        case SET_FORM_SUBMISSIONS_INCOMPLETE_COUNT:
            return { 
                ...state, 
                incompleteFormSubmissions: payload
            }

        default:
            return state
    }
};

export default formBuilderReducer;
