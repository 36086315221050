import React, { useEffect, useState } from 'react';
import { setEditorUtilities } from 'redux/actions/formBuilder';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { useEditor } from '@craftjs/core';

const CraftEditorInitializer = ({
  formSubmissionContent,
  isFieldsDisabled = false,
  span
}) => {
  const { actions, query, nodes } = useEditor((state) => ({
    nodes: state.nodes,
  }));

  const [isFilled, setFilled] = useState(false);

  const dispatch = useDispatch();

  const nonValuedControls = [
    'Container',
    'Card',
    'Panel',
    'Column',
    'Button',
    'Text',
    'Grid',
  ];

  useEffect(() => {
    if (
      actions &&
      Object.keys(actions).length > 2 &&
      query &&
      Object.keys(query).length > 2 &&
      nodes &&
      Object.keys(nodes).length > 0
    ) {
      dispatch(
        setEditorUtilities({
          actions,
          query,
          nodes,
        })
      );
    }
  }, [nodes]);

  useEffect(() => {
    if (
      formSubmissionContent &&
      formSubmissionContent.length > 0 &&
      !isFilled
    ) {
      Object.keys(nodes).map(async (serializeNodeKey) => {
        const nodeControl = query.getSerializedNodes()[serializeNodeKey];
        const name = nodeControl?.props.name;
        if (
          !nonValuedControls.includes(nodeControl.displayName) ||
          nodeControl.displayName === 'Grid'
        ) {
          const formControlItem = formSubmissionContent.find(
            (jsonFormItem) => jsonFormItem.id === serializeNodeKey
          );

          if (formControlItem) {
            await actions.setProp(serializeNodeKey, (props) => {
              if (!props.value) {
                let controlValue = formControlItem.value;
                const isValueADate = moment(formControlItem.value).isValid();

                if (isValueADate) {
                    controlValue = moment(formControlItem.value);
                }

                props.isDisabled = isFieldsDisabled;
                props.value = controlValue;
              }
            });
          } else {
            await actions.setProp(serializeNodeKey, (props) => {
              if (span === 'PAST' && !props.value) {
                props.isDisabled = true;
              }
            });
          }

          const formControlItemName = formSubmissionContent.find(
            (jsonFormItem) => jsonFormItem.name === name
          );

          if (formControlItemName) {
            // check all objects
            Object.keys(formControlItemName).map(async (key) => {
              await actions.setProp(serializeNodeKey, (props) => {
                  if (key === 'subTitle') {
                    props[key] = formControlItemName[key];
                  }
              });
            })
          }
        }
      });

      setFilled(true);
    } else {
      Object.keys(nodes).map(async (serializeNodeKey) => {
        await actions.setProp(serializeNodeKey, (props) => {
          if (span === 'PAST' && !props.value) {
            props.isDisabled = true;
          }
        });
      });
    }
  });

  return <></>;
};

export default CraftEditorInitializer;
