import React, {useState, FunctionComponent, useEffect} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import {Col, Row} from "react-bootstrap";
import OtpInput from "react-otp-input";
import {confirm, login} from 'api/login';
import {setToken} from 'api/token'
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AUTH_ACTIONS} from "../../../../redux/types/auth-types";
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './../../../../constants/index';
import { useTranslation } from 'react-i18next';
import { Statistic } from 'antd';
import { message } from 'helpers/notificationhelper';
import { setNotificationAsRead } from "redux/actions/notifications";

interface OTPComponentProps {
    length?: number;
    mobileNumber?: string;
}

const { Countdown } = Statistic;
const RESEND_OTP_COOLDOWN = 300000

const OTPComponent: FunctionComponent<OTPComponentProps> = ({length = 6, mobileNumber}) => {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [resendOtpOnCooldown, setResendOtpOnCooldown] = useState(false)
    const [error, setError] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (otp.length === length) {
            setLoading(true);
            let payload = {contactNumber: mobileNumber, code: otp};
            confirm(payload).then(e => {
                let token = e.data.data;
                dispatch({
                    type: AUTH_ACTIONS.SET_TOKEN,
                    payload: {token}
                })
                // localStorage.setItem('dpjTkn',token)
                Cookies.set(TOKEN_NAME, token, {
                    expires: 1,
                    path: ''
                });
                console.log(token)
                setToken(token)
                // @todo need to be able to route depending on permissions
                history.push('/upcoming-appointments')
            }).catch(error => {
                setLoading(false);
                setOtp("");
            })
        }
    }, [otp]);

    return (
        <Row className="justify-content-center">
            <Col xs={12} className="my-5">
                {loading ? (
                    <Spin indicator={<LoadingOutlined style={{fontSize: 36}} spin/>}/>
                ) : (
                    <>
                        <p className="mt-5">
                            Please enter the One Time Password sent to your mobile number
                        </p>
                        <OtpInput
                            isInputNum={true}
                            shouldAutoFocus={true}
                            onChange={(value) => {
                                setOtp(value);
                            }}
                            value={otp}
                            numInputs={6}
                            separator={<span> </span>}
                            containerStyle={{
                                justifyContent: "center",
                            }}
                            inputStyle={{
                                width: "45px",
                                height: "45px",
                                border: "none",
                                borderRadius: "10px",
                                boxShadow: "0px 4px 5px 0px rgb(55 101 246 / 20%)",
                                fontSize: "2.25rem",
                                margin: "5px",
                            }}
                        />
                        <p className="mt-5">
                            {resendOtpOnCooldown ? <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}><span>You can resend a One Time Password after</span><Countdown value={Date.now() + RESEND_OTP_COOLDOWN} onFinish={() => setResendOtpOnCooldown(false)} /><span>seconds</span></div> : <>Did not get one? <span className="text-primary" style={{
                                cursor: "pointer"
                            }} onClick={() => {
                                login({
                                    contactNumber: mobileNumber,
                                }).then(() => {
                                    setResendOtpOnCooldown(true)
                                }).catch(err => message.error(t("message.invalid_otp")))
                            }}>Tap here to resend</span></>}
                        </p>
                    </>
                )}
            </Col>
        </Row>
    );
};

export default OTPComponent;
