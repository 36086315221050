import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Tabs,
} from "@material-ui/core";
import {
  AccountCircle,
  ExitToApp,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { NavLink, useHistory } from "react-router-dom";
import routes from "routes";
import { keycloak } from "../../api/keycloak";
import { useTranslation } from "react-i18next";
import Tab from "@material-ui/core/Tab";
import SettingsModule from "modules/SettingsModule/index";
import { message, Modal } from "antd";
import { message as notificationMessage } from 'helpers/notificationhelper';
import Cookies from "js-cookie";
import {AUTH_ACTIONS} from 'redux/types/auth-types';
import { TOKEN_NAME } from "./../../constants/index";
import {useDispatch, useSelector} from 'react-redux';
import {
  logout
} from 'api/logout';
import { setNotificationAsRead, setNotificationAsClear} from "redux/actions/notifications";
import { getUser } from 'api/users';
import {
  setCurrentUser,
  showAccountSettings
} from 'redux/actions/currentUser';

function BottomNavigationMenu() {
  const [toggleAccountDrawer, setToggleAccountDrawer] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const showSettings = useSelector(state => state.currentUser.showAccountSettings);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { confirm } = Modal;

  useEffect(() => {
    if (showSettings) {
      setIsSettingsOpen(true);
    } else if (!showSettings && showSettings !== undefined) {
      setIsSettingsOpen(false);
    } else {}
  }, [showSettings]);

  const fetchCurrentUser = async () => {
    const res = await getUser();
    dispatch(setCurrentUser(res.data));
  };

  const accountMenus = [
    {
      text: "Account Settings",
      icon: <SettingsIcon />,
      onClick: () => {
        fetchCurrentUser();
        dispatch(showAccountSettings(true));
      },
    },
    {
      text: "Logout",
      icon: <ExitToApp />,
      onClick: () => {
        setToggleAccountDrawer(false)
        confirm({
          title: 'Confirm',
          content: 'Are you sure you want to logout?',
          okText: 'Yes',
          cancelText: 'No',
          maskClosable: true,
          onOk: () => {
            message.loading(t("message.signing_out"), 0);
            if(Cookies.get(TOKEN_NAME)) {
              dispatch(setNotificationAsRead());
              dispatch(setNotificationAsClear());
            }
            logout({})
            .then(e => {
              if (e && e.status === 200) {
                message.success('You have successfully logged out from the App.');
              }
            }).catch(e => {
                if (e && e.status === 500) {
                  notificationMessage.error("An error has occurred. Please try again later.")
                }
              });
          }
        });
      },
    },
  ];

  const onClicklogout = (e) => {
    e.preventDefault();
    setToggleAccountDrawer(true);
  };

  return (
    <>
      <Tabs
        value={false}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        className="d-flex d-lg-none"
        textColor="primary"
        style={{
          position: "fixed",
          bottom: "0",
          height: "unset",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        {routes() &&
          routes().length > 0 &&
          routes().map((route) => (
            <Tab
              key={route.path}
              style={{ alignSelf: "flex-start", maxWidth: "8em" }}
              className={`${route.className} botton-nav-link`}
              icon={route.icon}
              label={<small style={{}}>{route.title}</small>}
              component={NavLink}
              to={route.path}
            />
          ))}
        <Tab
          className='BottomNavigationTab'
          style={{ alignSelf: "flex-start", maxWidth: "8em" }}
          label={<small>MY ACCOUNT</small>}
          icon={<AccountCircle fontSize="large" />}
          onClick={onClicklogout}
        />
      </Tabs>
      <SwipeableDrawer
        anchor={"bottom"}
        open={toggleAccountDrawer}
        onClose={() => setToggleAccountDrawer(false)}
        onOpen={() => setToggleAccountDrawer(true)}
      >
        <List>
          {accountMenus.map((menu, index) => (
            <ListItem button key={index} onClick={menu.onClick}>
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText primary={menu.text} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <SettingsModule
        isOpen={isSettingsOpen}
        handleClose={() => {
          setToggleAccountDrawer(false);
          dispatch(showAccountSettings(false));
        }}
      />
    </>
  );
}

export default BottomNavigationMenu;
