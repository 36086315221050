import React, {FunctionComponent, useEffect} from "react";
import {useAppSelector} from "../hooks/use-app-selector";
import {useDispatch} from "react-redux";
import {getPublicSetting} from "../api/public/settings";
import {TENANT_ACTIONS} from "../redux/types/tenant-types";
import Interweave from 'interweave';

interface LoginMessageProps {

}

const LoginMessage: FunctionComponent<LoginMessageProps> = (props) => {
    const tenantSettings = useAppSelector(state => state.tenant.settings);
    const dispatch = useDispatch();

    async function getAndSetTenantLoginMessage() {
        const res = await getPublicSetting({
            category: 'TENANT',
            dataType: 'FREE_TEXT',
            name: 'LOGIN_MESSAGE'
        })


        const updatedTenantSettings = {
            ...tenantSettings,
            loginMessage: res.data.value
        }

        dispatch({
            type: TENANT_ACTIONS.SET_TENANT_SETTINGS,
            payload: updatedTenantSettings
        })
    }

    useEffect(() => {
        getAndSetTenantLoginMessage()
    }, [])

    return <>{tenantSettings.loginMessage ? <Interweave content={tenantSettings.loginMessage} /> : null}</>
}

export default LoginMessage;