export enum MessageTypes {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}

export interface ValidationObject {
    type: string,
    message: string,
}

export interface FieldComponentSelect {
    value: any,
    displayText: string
}

export interface FieldComponent {
    id: string,
    label?: string,
    name: string,
    placeholder?: string,
    size: string | null,
    type: string,
    defaultValue: string | null,
    isMaterialDatePicker?: boolean | undefined,
    items?: FieldComponentSelect[],
    props?: Object,
    validation?: ValidationObject[]
}