import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import { message } from 'helpers/notificationhelper';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addAppointmentMessage } from 'api/messages';
import { fetchAppointmentRequest } from 'redux/actions/appointment';

const ReplyModal = props => {
    const { visible, hide, appointmentId, subject, messageId, onSuccess } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { TextArea } = Input;
    const [reply, setReply] = useState({subject: subject, message: '', replyingToMessageId: messageId});

    const addReply = () => {
        addAppointmentMessage(appointmentId, reply)
            .then(res => {
                message.success(t('message.reply_sent_success'));
                if(appointmentId) {
                    dispatch(fetchAppointmentRequest(appointmentId));
                }
                setReply({subject: subject, message: '', replyingToMessageId: messageId});
                onSuccess();
                hide();
            })
            .catch(err => {
                message.error(t('message.reply_sent_error'));
            })
    }

    const onCancel = () => {
        setReply({subject: subject, message: '', replyingToMessageId: messageId});
        hide();
    }

    return (
        <Modal
            title={(
                <span>
                    <strong>{t('label.subject')}:</strong>&nbsp;&nbsp;
                    <span style={{ fontWeight: 'normal' }}>{reply.subject}</span>
                </span>
            )}
            visible={visible}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={[
                <Button
                    key='reply'
                    onClick={addReply} 
                    disabled={!(reply.subject !== '' && reply.message !== '')}
                >
                    { t('label.reply') }
                </Button>
            ]}
            centered
            zIndex={999}
        >
            <label>{t('label.message')}:</label><br/>
            <TextArea 
                rows={4} 
                placeholder={t('placeholder.enter_message')} 
                value={reply.message} 
                onChange={e => setReply({...reply, message: e.target.value})} 
            />
        </Modal>
    )
}

export default ReplyModal;