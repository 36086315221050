import {combineReducers} from 'redux';
import uiReducer from './uiReducer';
import zoomReducer from './zoomReducer';
import onBoardingReducer from './onBoardingReducer';
import notificationsReducer from './notificationReducer';
import documentsReducer from './documentsReducer';
import messageReducer from './messageReducer';
import appointmentReducer from './appointmentReducer';
import i18nReducer from './i18nReducer';
import formBuilderReducer from './formBuilderReducer';
import user from "./user";
import currentUserReducer from './currentUserReducer';
import taskReducer from './taskReducer';
import tenantReducer from './tenant-reducer';
import permissionsReducer from './permissions-reducer';
import authReducer from "./auth-reducer";
import errorReducer from './error-reducer';
import applicationSettingReducer from './applicationsettings';

const rootReducer = combineReducers({
    appointment: appointmentReducer,
    documents: documentsReducer,
    messages: messageReducer,
    notifications: notificationsReducer,
    i18n: i18nReducer,
    ui: uiReducer,
    onBoarding: onBoardingReducer,
    zoom: zoomReducer,
    formBuilder: formBuilderReducer,
    users: user,
    currentUser: currentUserReducer,
    applicationSettings:applicationSettingReducer,
    taskReducer: taskReducer,
    tenant: tenantReducer,
    permissions: permissionsReducer,
    auth: authReducer,
    error: errorReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;