import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Inbox from "pages/Inbox";
import {
    EventNote,
    FavoriteBorder,
    FileCopy,
    FormatListNumbered,
    MailOutline,
    MenuBook,
    Restore
} from '@material-ui/icons';
import ViewUpcomingDetail from "pages/Appointments/UpcomingAppointments/View";
import Messages from 'pages/Messages/Details';
import {useTranslation} from 'react-i18next';
import Resources from "../pages/Resources/Resources";
import Forms from "pages/Forms/Forms";
import Tasks from "pages/Tasks/Tasks";
import {Badge} from "antd";
import { useAppSelector } from '../hooks/use-app-selector';
import AppointmentsModule from 'modules/AppointmentsModule';
import { UPCOMING } from 'constants/appointment-constants';
import { PAST } from 'constants/appointment-constants';
import Heartrate from "../pages/Heartrate";

const Translate = (prop: any) => {
    const { t } = useTranslation();

    return <>{t(prop.keyword)}</>;
};

const Routes = (enableQuery = false) => {
    const { t } = useTranslation();

    const permissions = useAppSelector(state => state.permissions.permissions);
    const incompletePatientTasks = useAppSelector(state => state.taskReducer.incompletePatientTasks);
    const incompleteFormSubmissions = useAppSelector(state => state.formBuilder.incompleteFormSubmissions);
    const messagesUnreadCount = useAppSelector(state => state.messages.messagesUnreadCount);

    return ([
        permissions.Appointments ? {
            className: 'upcoming-appointments-link',
            path: '/upcoming-appointments',
            title: <Translate keyword='label.upcoming_appointments' />,
            bottomTitle: t('label.upcoming_appointments'),
            icon: <EventNote fontSize='large' />,
            component: <AppointmentsModule span={UPCOMING} />,
            components: [
                {
                    path: '/:id',
                    title: 'Appointment Details',
                    component: <ViewUpcomingDetail  span={UPCOMING}/>,
                }
            ]
        } : null,
        permissions.Appointments ? {
            className: 'past-appointments-link',
            path: '/past-appointments',
            title: <Translate keyword='label.past_appointments' />,
            bottomTitle: t('label.past_appointments'),
            icon: <Restore fontSize='large' />,
            component: <AppointmentsModule span={PAST} />,
            components: [
                {
                    path: '/:id',
                    title: 'Appointment Details',
                    component: <ViewUpcomingDetail span={PAST} />,
                }
            ]
        } : null,
        permissions.Inbox ? {
            className: 'inbox-link',
            path: '/inbox',
            title: <Translate keyword='label.inbox' />,
            bottomTitle: t('label.inbox'),
            icon: <Badge count={messagesUnreadCount}><MailOutline fontSize='large' /></Badge>,
            component: <Inbox />,
            breadcrumbName: 'Inbox',
            components: [
                {
                    path: '/:id',
                    title: '',
                    component: <Messages />,
                }
            ]
        } : null,
        permissions.Heartrate ? {
            className: 'heartrate-link',
            path: '/heartrate',
            title: <Translate keyword='label.heartrate' />,
            icon: <FavoriteBorder fontSize='large' />,
            component: <Heartrate />,
            breadcrumbName: 'Heartrate',
            components: [
                {
                    path: '/:id',
                    title: '',
                    component: <Heartrate />,
                }
            ]
        } : null,
        permissions.Resources ? {
            className: 'resource-link',
            path: '/resources',
            title: <Translate keyword='label.collateral' />,
            icon: <MenuBook fontSize='large' />,
            component: <Resources />,
            breadcrumbName: 'Resources',
        } : null,
        permissions.Forms ? {
            className: 'form-link',
            path: '/forms',
            title: <Translate keyword='label.form_plural' />,
            icon: <Badge count={incompleteFormSubmissions}><FileCopy fontSize='large' /></Badge>,
            component: <Forms />,
            breadcrumbName: 'Forms',
        } : null,
        permissions.Tasks ? {
            className: 'task-link',
            path: '/tasks',
            title: <Translate keyword='label.task' />,
            icon: <Badge count={incompletePatientTasks}><FormatListNumbered fontSize='large'/></Badge>,
            component: <Tasks />,
            breadcrumbName: 'Tasks',
        } : null
    ]).filter(element => element);
}

export default Routes;