import React from "react";
import { Input } from "antd";
import Cleave from 'cleave.js/react';

const CleaveInputField = ({
  field,
  form: { touched, errors },
  meta,
  ...props
}) => {

  return (
    <>
      <label>{props.label}</label>
      <Cleave
        className={`ant-input ant-input-lg Form-Input ${touched[field.name] && errors[field.name] && 'Error'}`}
        {...props}
        {...field}
      />
        {touched[field.name] && errors[field.name] && (
          <p className="text-danger Error-Text">{errors[field.name]}</p>
        )}
    </>
  );
};

export default CleaveInputField;
