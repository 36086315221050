import { Button, Card, Empty } from 'antd';
import { message } from 'helpers/notificationhelper'
import { API_BASE_URL } from 'api/config';
import { Container, Row, Col } from 'react-bootstrap';
import { Attachment } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCollateralFile, getCollateralFileUrl, getAppointmentCollaterals } from 'api/appointmentCollaterals';
import {openCollateral} from "../../api/resources";

function CollateralList({appointmentId}) {
    const [ collaterals, setCollaterals ] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if(collaterals.length === 0 && appointmentId) {
            const params = {
                appointmentId: appointmentId,
                length: 999, // TBC: How to know the length of collaterals to be shown in the appointment
                page: 0,
                sortColumn: 'id',
                sortDirection: 'ASC'
            }
            fetchAppointmentCollaterals(appointmentId, params);
        }
    }, [appointmentId]);

    const fetchAppointmentCollaterals = async (id, params) => {
        const res = await getAppointmentCollaterals(id, params);
        setCollaterals(res.data.data);
    }

    const handleDownloadPreviewLink = (appointmentCollateralId, collateral) => {
        getCollateralFileUrl(appointmentCollateralId)
          .then(res=>{
              window.open(res.data)
          })
          .catch(err => {
              message.error('Error downloading file.');
          })
    };

    return (
        <Card className='Card-Grids' style={{border:'none', borderRadius: '10px'}}>
            {collaterals && collaterals.length < 1 && (
                <Container fluid>
                    <Row>
                        <Col className='text-center mt-4 mb-4' style={{ minHeight: '23vh' }}>
                            <Empty description={t('message.no_collateral')} />
                        </Col>
                    </Row>
                </Container>
            )}
            <Row>
                {collaterals && collaterals.length > 0 && collaterals.map((appointmentCollateral, i) => (
                    <Card.Grid key={i} className='col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-6'>
                        <Container fluid>
                            <Row>
                                <Col className='text-center'>
                                    <Attachment fontSize={'large'} />
                                    <h4 className='Attachment-Card-Title'>{appointmentCollateral.collateral.name}</h4>
                                    <Button 
                                        type='link' 
                                        onClick={() => handleDownloadPreviewLink(appointmentCollateral.id, appointmentCollateral.collateral)}
                                    >
                                        {appointmentCollateral && appointmentCollateral.collateral.hasOwnProperty('url') ? t('label.open_link') : t('label.download_file')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Grid>
                ))}
            </Row>
        </Card>
    );
}

export default CollateralList;