import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from "./redux/store/configureStore";
import App from './App';
import 'translations/i18n';
import './index.scss';
import './style.scss';
import axios from 'axios';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ErrorNotifier from "./components/ErrorNotifier";

export const store = configureStore();

axios.defaults.baseURL = 'https://api-slhd.fftest.com.au';

axios.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Raleway',
        fontSize: 22,
    },
})

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <ErrorNotifier />
            <App/>
        </ThemeProvider>
    </Provider>
    ,
    document.getElementById('wrapper')
);

