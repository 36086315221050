import { SET_APPLICATION_SETTINGS } from 'redux/actions/types/applicationsettings';

const INITIAL_STATE = {
    data: undefined
};

const applicationSettingReducer = (state = INITIAL_STATE, action) => {
    const { type, payload, error } = action;
    
    switch (type) {

    case SET_APPLICATION_SETTINGS: {
        return {
            data: payload
        }
    }

    default:
        return state
    }
};

export default applicationSettingReducer;