import React from 'react';

import { useSelector } from 'react-redux';

import FiveFacesLogo from 'assets/images/5f-dark-logo.png';

const Footer = () => {
    const tenantState = useSelector(state => state.tenant);

    return (
        // tenantState.branding.isPoweredByVisible === true || tenantState.branding.isPoweredByVisible === undefined ? 
        <>
            <div className='text-right'>
                <small>
                    {/* <strong style={{marginRight: '0'}}>Powered by:</strong><br/> */}
                    <small>
                        <img src={'https://files.fivefaces.com.au/images/FiveFaces_PoweredBy_Navy.svg'}
                            alt='5F Logo'
                            style={{marginTop: '15px', width: '150px', height: 'auto'}}
                        />
                    </small>
                </small>
            </div>
        </> 
        // : <div style={{ padding: '20px' }}></div>
    );
};

export default Footer;