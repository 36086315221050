export const UPCOMING = "UPCOMING";
export const PAST = "PAST";
export const PAST_APPOINTMENTS = "past-appointments";
export const UPCOMING_APPOINTMENTS = "upcoming-appointments";
export const TELEHEALTH_ATTENDANCE_TYPE = "TELEHEALTH";

export const STATUS_COMPLETE = 'COMPLETE';
export const STATUS_CANCELLED = 'CANCELLED';
export const STATUS_DID_NOT_ATTEND = 'DID_NOT_ATTEND';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_SCHEDULED = 'SCHEDULED';
export const STATUS_CONFIRMED = 'CONFIRMED';
