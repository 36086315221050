import React from 'react';
import { Input } from 'antd';
import { preventNonAlphaNumericalInput } from 'helpers/utils';

const AlphaNumericField = ({
    field,
    form: { setFieldValue ,touched, errors, values },
    meta,
    maxLength,
    ...props
}) => {
    const checkInputLength = (e) => {
        setFieldValue(field.name,e.target.value.slice(0, maxLength));
    }

    // for mobile chrome (android) - this is special issue occur in android chrome so prevent that i added this
    const preventNonAlphaNumericalInputForMobile = (e) => {
        e.target.value = e.target.value.replace(/[\W_]+/g,'');
        return false;
    }

    return (
        <>
            <label>{props.label}</label>
            <Input
                className={`Form-Input ${field.name !== 'dva' && touched[field.name] && errors[field.name] && 'Error'}`}
                pattern="[a-zA-Z0-9]+"
                {...props}
                {...field}
                onKeyPress={(e) => preventNonAlphaNumericalInput(e)}
                onKeyUp={(e)=>preventNonAlphaNumericalInputForMobile(e)}
                onInvalid={(e) => { e.target.setCustomValidity('Invalid value') }}
                onChange={(e) => { 
                    e.target.setCustomValidity('');
                    maxLength && checkInputLength(e);
                }}
                disabled={(field.name === 'dva' && (values['medicareNo'] || values['irn'])) ? true:false}
            />
            {field.name !== 'dva' && touched[field.name] && errors[field.name] && (
                <p className='text-danger Error-Text'>{errors[field.name]}</p>
            )}
        </>
    )
};

export default AlphaNumericField;
