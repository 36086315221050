import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

const rolePermissionsApi = createAxios({
    baseURL: API_BASE_URL + '/role-permission',
});

export const getCurrentUserPermission = () => {
    return rolePermissionsApi.get('/current-user?role=PATIENT');
};
