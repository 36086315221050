import {
    SET_EDITOR_UTILITIES,
    SET_FORM_SUBMISSIONS_INCOMPLETE_COUNT
} from 'redux/actions/types/formBuilder';

export const setEditorUtilities = utilities => ({
    type: SET_EDITOR_UTILITIES,
    payload: utilities
});

export const setFormSubmissionsIncompleteCount = count => ({
    type: SET_FORM_SUBMISSIONS_INCOMPLETE_COUNT,
    payload: count
});
