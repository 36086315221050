import { notification } from 'antd';
const timeduration = 10;
export const message:any = () => {};

message.success = (msg:string,duration:number=timeduration) => {
    console.log("msg",msg);
    notification['success']({
        message:'',
        description:msg,
        duration:duration,
    })
    return true;
}

message.error = (msg,duration=timeduration) => {
    notification['error']({
        message:'',
        description:msg,
        duration:duration
    })
    return true;
}

message.info = (msg,duration=timeduration) => {
    notification['info']({
        message:'',
        description:msg,
        duration:duration
    })
    return true;
}

message.warning = (msg,duration=timeduration) => {
    notification['warning']({
        message:'',
        description:msg,
        duration:duration
    })
    return true;
}

message.warn = (msg,duration=timeduration) => {
    notification['warn']({
        message:'',
        description:msg,
        duration:duration
    })
    return true;
}