import {InviteRegistrationType, StagingRegistrationType} from 'mockData';
import {
    TenantAction,
    TenantState,
    TENANT_ACTIONS,
    AUTHENTICATION_TYPES
} from "./../types/tenant-types";

const initialState: TenantState = {
    id: undefined,
    uniqueId: 'Default.patient',
    name: undefined,
    branding: {
        logo: undefined,
        isPoweredByVisible: true,
        tenantUniqueId: null,
        page: {
            appTitle: 'Digital Patient Journey',
            appSubTitle: 'Digital Patient Journey',
            navbarHeaderTitle: 'Five Faces Pty. Ltd.'
        },
        theme: {
            palette: {
                primary: {
                    light: '#0170FE',
                    main: '#0170FE',
                    dark: '#193B68'
                },
                navbar: {
                    background: '#FFFFFF',
                    text: '#193B68'
                },
                sidebar: {
                    mainText: '#193B68',
                    activeBackground: '#0170FE',
                    activeText: '#FFFFFF'
                },
                header: {
                    primary: '#193B68',
                    secondary: '#193B68'
                },
                text: {
                    primary: '#404040'
                },
                button: {
                    primaryBackground: '#0170FE',
                    text: '#FFFFFF',
                    secondaryBackground: '#0170FE'
                },
                background: {
                    default: '#F3F8FF'
                }
            },
            typography: {
                htmlFontSize: '',
                fontFamily: 'Raleway',
                fontUrl: '',
                font: {}
            }
        }
    },
    authenticationType: [AUTHENTICATION_TYPES.MOBILE],
    registration: InviteRegistrationType,
    settings: {}
};

export default function tenantReducer(
    state = initialState,
    action: TenantAction
): TenantState {
    const {type, payload} = action;
    switch (type) {
        case TENANT_ACTIONS.SET_TENANT:
            return {
                ...state,
                ...payload,
            };

        case TENANT_ACTIONS.SET_TENANT_BRANDING: {
            let newTenantState = {...state};

            if (payload.branding) {
                newTenantState.branding = {...payload.branding}
            } else {
                newTenantState.branding = {...state.branding}
            }

            return {
                ...state,
                ...newTenantState
            }
        }

        case TENANT_ACTIONS.SET_TENANT_SETTINGS: {
            return {
                ...state,
                settings: payload
            }
        }

        case TENANT_ACTIONS.FETCH_TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS_SUCCESS: {
            return  {
                ...state,
                settings: {
                    ...state.settings,
                    upcomingAppointmentsExtraOptions: payload
                }
            }
        }

        default:
            return {...state};
    }
}
