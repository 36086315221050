import React, { useState, useMemo, useEffect } from 'react';
import {useLocation} from "react-router-dom";
import Breadcrumbs from '../components/Breadcrumbs';
import { Launcher } from 'react-chat-window';
import { Button } from "antd";

import Footer from 'components/Layout/Footer';

import { setTourVisibility } from 'redux/actions/ui';

import { connect } from 'react-redux';

import Tour from 'reactour';
import { useAppSelector } from './../hooks/use-app-selector';
import {AUTH_ACTIONS} from './../redux/types/auth-types';
import Cookies from 'js-cookie';
import {useDispatch} from 'react-redux';
import { TOKEN_NAME } from './../constants/index';
import { message } from 'helpers/notificationhelper';
import IdleTimer from "react-idle-timer";
import {
    logout
} from 'api/logout';
import { setNotificationAsRead, setNotificationAsClear } from 'redux/actions/notifications';

function BasePage(props) {
    const { pageTitle, pageName, children, isTourVisible, setTourVisibility } =
        props;
    const [messageList, setMessageList] = useState([]);
    const currentUser = useAppSelector(state => state.currentUser.data);
    const location = useLocation();
    const dispatch = useDispatch();

    let permissions = useAppSelector((state) => state.permissions.permissions);
    const logouttime = useAppSelector((state) => state.applicationSettings.data);
    let logouttimeout = parseFloat(logouttime?.logoutInterval ?? 1000);

    useMemo(() => { }, [permissions]);

    const _onMessageWasSent = (message) => {
        setMessageList([
            ...messageList,
            message,
            {
                author: 'them',
                type: 'text',
                data: { text: 'Conversation scripts have not been setup yet.' },
            },
        ]);
    };

    const steps = [
        {
            selector: '.upcoming-appointments-link',
            content: 'Click this link to see the list of upcoming appointments',
        },
        {
            selector: '.past-appointments-link',
            content: 'Click this link to see the list of past appointments',
        },
        {
            selector: '.inbox-link',
            content: 'Click this link to see the list of messages',
        },
        {
            selector: '.heartrate-link',
            content: 'Click this link to see the output from your heart monitor',
        },
        {
            selector: '.sc-launcher',
            content: 'If you need some help just click this balloon.',
        },
    ];

    const onIdle = e => {
        const payload = {
            contactNumber: currentUser?.mobileNumber?.trim(),
        };
        if(Cookies.get(TOKEN_NAME)) {
            dispatch(setNotificationAsRead());
            dispatch(setNotificationAsClear());
        }
        logout(payload)
        .then(e => {
            if (e && e.status === 200) {
                message.success('You have successfully logged out from the App.');
            }
        })
        .catch(e => {
            if (e && e.status === 500) {
                message.error("An error has occurred. Please try again later.")
            }
        });

      };

    return (
        <div id="basePage">
            {/* <Breadcrumbs /> */}
            <h1 className="fivef-pageTitle">{pageTitle ? pageTitle : pageName}</h1>
            <IdleTimer
                element={document}
                onIdle={onIdle}
                timeout={1000 * logouttimeout}
            />
            {children}
            <Footer />
            {permissions.Chatbot ? (
                <Launcher
                    agentProfile={{
                        teamName: "Sydney LHD",
                        imageUrl:
                            "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
                    }}
                    onMessageWasSent={_onMessageWasSent.bind(this)}
                    messageList={messageList}
                    showEmoji
                />
            ) : null}
            <Tour
                steps={steps}
                isOpen={isTourVisible}
                lastStepNextButton={<Button type="primary">Done!</Button>}
                disableInteraction={true}
                onRequestClose={() => {
                    setTourVisibility(false);
                    localStorage.setItem("onboarded", true);
                }}
            />
        </div>
    );
}

export default connect(
    (state) => {
        return {
            isTourVisible: state.ui.isTourVisible,
            pageTitle: state.ui.pageTitle,
        };
    },
    (dispatch) => {
        return {
            setTourVisibility: (isTourVisible) =>
                dispatch(setTourVisibility(isTourVisible)),
        };
    }
)(BasePage);
