import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
    ConfirmationNumber
  } from '@material-ui/icons';
  import { Tag } from 'antd';

const StyledCol = styled(Col)`
    height: calc(100% - 17px);
    &.form > .container-fluid {
        height: inherit;
    }
`;

function AppointmentSection(props) {
    const { title, children, ticketNumber, showQrCode = false, displayBarcode = false, barcode = '' } = props;
    
    return (
        <>
            <Row style={{height: '100%'}}>
                {/* {showQrCode && displayBarcode && barcode && <Col xs={12} md={12} lg={12} className='d-flex justify-content-end w-100 mb-4'>
                    <img className='img-responsive Qr-Code' src={`data:image/png;base64,${barcode}`} />
                </Col>} */}
                {title && <Col xs={12} md={12} lg={12} style={{height: '25px'}} className='mb-3'>
                    <div className="d-flex justify-content-between">
                        <h4>
                            <strong className='text-uppercase text-bolder' style={{ color: '#343434' }}>{title}</strong>
                        </h4>
                        {/* {
                            ticketNumber &&
                            <div>
                                <Tag className="Appointment-Details-Ticket-Number">
                                    <div style={{fontSize: '12px'}}>Ticket Number</div>
                                    <div style={{fontSize: '14px', fontWeight: 'bold'}}>{ticketNumber}</div>
                                </Tag>
                            </div>
                        } */}
                    </div>
                </Col>}
                <StyledCol className={title === 'Forms' && 'form'} xs={12} md={12} lg={12}>
                    {children}
                </StyledCol>
            </Row><br/>
        </>
    );
}

export default AppointmentSection;