import React, {useState} from 'react';
import { preventNonNumericalInput } from 'helpers/utils';

const StrictNumberField = ({
    field,
    form: { touched, errors, setFieldValue, getFieldValue },
    onChange,
    meta,
    ...props
}) => {
    const [numberValue, setNumberValue] = useState(null);

    const onChangeNumber = e => {
        const { value } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            field.value = value;
            setFieldValue(field.name, value); 
        }
    };

    return (
        <>
            <label>{props.label}</label>
            <input
                pattern="[0-9]*"
                className={`ant-input Form-Input ${touched[field.name] && errors[field.name] && 'Error'}`}
                {...props}
                {...field}
                onKeyPress={(e) => preventNonNumericalInput(e)}
                onInvalid={(e) => { e.target.setCustomValidity('Invalid value') }}
                onChange={(e) => { e.target.setCustomValidity('');
                onChangeNumber(e);
            }}
            maxLength="11"
            />
            {touched[field.name] && errors[field.name] && (
                <p className='text-danger Error-Text'>{errors[field.name]}</p>
            )}
        </>
    )
};

export default StrictNumberField;
