import { PermissionsAction, PermissionsState, PERMISSIONS_ACTIONS } from './../types/permissions-types/index';

  const initialState: PermissionsState = {
        permissions: {
          "Appointments": [],
          "Inbox": [],
          "Settings": ["settings:update-preferred-language", "settings:delete-account"]
        }
  };
  
  export default function permissionsReducer(
    state = initialState,
    action: PermissionsAction
  ): PermissionsState {
    const { type, payload } = action;

    switch (type) {
      case PERMISSIONS_ACTIONS.SET_PERMISSIONS:
        return {
          ...state,
          permissions: Object.keys(payload.permissions).length === 0 ? initialState.permissions : payload?.permissions,
        };
      default:
        return { ...state };
    }
  }
  