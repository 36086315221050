import React, {FunctionComponent, useEffect} from "react";
import {useAppSelector} from "../hooks/use-app-selector";
import {message} from "helpers/notificationhelper";
import {store} from "../index";
import {ERROR_ACTIONS} from "../redux/types/error-types";

interface ErrorNotifierProps {

}

const ErrorNotifier: FunctionComponent<ErrorNotifierProps> = (props) => {

    const error = useAppSelector(state => state.error)
    
    useEffect(() => {
        if (error.message) {
            message.error(
                <div style={{whiteSpace: 'break-spaces'}} dangerouslySetInnerHTML={{__html: error.message}}></div>
            )
        }
        store.dispatch({
            type: ERROR_ACTIONS.SET_ERROR_MESSAGE,
            payload: ''
        })
    }, [error.message])

    return <></>

}

export default ErrorNotifier;

