import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge, Empty, Spin } from 'antd';
import NotificationItem from 'components/Notifications/NotificationItem';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchNotificationsListByUserId,
    setNotificationAsRead,
} from 'redux/actions/notifications';
import {
    setNotificationsVisibility
} from 'redux/actions/ui';
import 'styles/Notifications.scss';
import { findParentElementBySelector } from 'helpers/utils';

const NotificationList = () => {
    const notificationState = useSelector(state => state.notifications);
    const uiState = useSelector(state => state.ui);
    const dispatch = useDispatch();

    const { isNotificationsListLoading, list, tally } = notificationState;
    const { isNotificationsVisible } = uiState;

    const notificationsList = list;

    useEffect(() => {
        document.addEventListener('click',  (event) => {
            if (!findParentElementBySelector(event.target, '.Notification-Component')) {
                dispatch(setNotificationsVisibility(false));
            }
        }, true);
    }, []);

    const handleNotificationIconClick = () => {
        let currentNotificationStatus = true;

        if (uiState.isNotificationsVisible) {
            currentNotificationStatus = false;
        } else {
            // dispatch(setNotificationAsRead(0));
            dispatch(fetchNotificationsListByUserId(0, 1, -1));
        }

        dispatch(setNotificationsVisibility(currentNotificationStatus));
    };

    return (
        <div className='Notification-Component'>
            <Badge count={tally}>
                <NotificationsIcon fontSize='large' onClick={handleNotificationIconClick} />
            </Badge>
            {isNotificationsVisible && <div className='Notifications-List-Container'>
                <h4 className='Notification-Title'>Notifications</h4>
                <div className={(isNotificationsListLoading && notificationsList.length < 1 ? 'Flex-Display Notification-List' : 'Notification-List')}>
                    {isNotificationsListLoading && notificationsList.length < 1 && (
                        <Spin size="large" />
                    )}
                    {notificationsList.length < 1 && !notificationState.isNotificationsListLoading && (
                        <Empty description={'No new notification'} />
                    )}
                    {notificationsList && notificationsList.length > 0 && notificationsList.map(notification => {
                        const { id, subject, phoneNumber, emailAddress, message, status, notificationType, formSubmission } = notification;
                        const isNotificationMessageType = notification?.appointmentMessage?.id;
                        const notificationItemId = notification?.appointmentMessage?.id || notification?.appointment?.id;

                        return <NotificationItem
                                    key={id}
                                    index={id}
                                    id={notificationItemId}
                                    subject={subject}
                                    phoneNumber={phoneNumber}
                                    emailAddress={emailAddress}
                                    message={message}
                                    status={status}
                                    appointment={notification?.appointment}
                                    isMessageType={isNotificationMessageType}
                                    notificationType={notificationType}
                                    formSubmission={formSubmission}
                                /> 
                    })}
                </div>
                <div className='text-center mt-2'>
                    {/* <Link to={'/inbox'} onClick={() => dispatch(setNotificationsVisibility(false))}>See all messages</Link> */}
                </div>
            </div>}
        </div>
    );
};

export default NotificationList;
