import React, {useEffect, useState} from 'react';
import {Card, Input, Table, List, Button, Tag, Modal} from 'antd';
import { message } from 'helpers/notificationhelper';
import {Link} from 'react-router-dom';
import {getPatientTasks, updatePatientTaskStatus} from 'api/patientTasks';
import UploadAttachmentModal from 'components/Appointments/UploadAttachmentModal';
import FormSubmitModal from 'components/Appointments/FormSubmitModal';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import {getCollateralFileUrl, getCollateralFile} from 'api/appointmentCollaterals';
import {CompleteButton} from '../../components/CompleteButton';
import {CheckCircleFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

const Tasks = () => {
  const [appointmentId, setAppointmentId] = useState();
  const [attachmentUploadVisible, setAttachmentUploadVisible] = useState(false)
  const [formVisible, setFormVisible] = useState(false);
  const [activeSubmissionId, setActiveSubmissionId] = useState();
  const [designId, setDesignId] = useState();
  const [formName, setFormName] = useState();
  const [visibleCustom, setVisibleCustom] = useState(false);
  const [taskId, setTaskId] = useState();
  const [customTask, setCustomTask] = useState();
  const [draw, setDraw] = useState(0);
  const today = Date.parse(new Date());
  const {t} = useTranslation();

  const cols = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record, value) => {
        let appSched = Date.parse(record.appointment.appointmentDateTime);
        let base = appSched >= today
          ? 'upcoming-appointments'
          : 'past-appointments';
        return <>{text}<br/><Link
          to={`/${base}/${record.appointment.id}`}>{record.appointment.appointmentType}</Link></>
      }
    },
    {
      title: 'Action',
      dataIndex: 'taskType',
      key: 'taskType',
      render: (text, record, value) => {
        if (record.status === 'NEW') {

          return <CompleteButton
            type='primary'
            onClick={() => {
              setVisible(record);
            }}
          >{t('label.click_to_complete')}</CompleteButton>
        } else {
          return <Tag color='green'
                      icon={<CheckCircleFilled style={{position: 'relative', top: '-2px', height: '10px'}}/>}>DONE</Tag>

        }
      }
    }
  ];
  const handleDownloadPreviewLink = (collateral) => {
    if (collateral && collateral.collateral?.hasOwnProperty('url')) {
      getCollateralFileUrl(collateral.id)
        .then(res => window.open(res.data))
    } else if (collateral && collateral.collateral?.hasOwnProperty('file')) {
      getCollateralFile(collateral.id)
        .then(res => window.open(res.data));
    } else {
      message.error('No link/file found')
    }
  };

  const completeTask = (id) => {
    updatePatientTaskStatus(id ?? taskId, 'COMPLETED')
      .then(res => {
        setDraw(draw + 1);
      })
  }

  const setVisible = record => {
    setTaskId(record.id);
    switch (record.taskType) {
      case 'FORM':
        if (record.formSubmission !== undefined) {
          setActiveSubmissionId(record.formSubmission.id)
          setDesignId(record.formSubmission.formDesign.id)
          setFormName(record.formSubmission.formDesign.name)
          setFormVisible(true)
        }
        break;
      case 'COLLATERAL':
        handleDownloadPreviewLink(record?.collateral)
        completeTask(record.id)
        break;
      case 'ATTACHMENT':
        setAppointmentId(record.appointment.id)
        setAttachmentUploadVisible(true)
        break;
      case 'CUSTOM':
        setVisibleCustom(true)
        setCustomTask(record.id);
        break;
    }
  }
  return (
    <>
      <UploadAttachmentModal
        visible={attachmentUploadVisible}
        setVisible={setAttachmentUploadVisible}
        appointmentId={appointmentId}
        onSuccess={completeTask}
      />
      <FormSubmitModal
        visible={formVisible}
        setVisible={setFormVisible}
        fullScreen={false}
        activeSubmissionId={activeSubmissionId}
        designId={designId}
        name={formName}
        onSuccess={completeTask}
      />
      <Modal
        title={null}
        visible={visibleCustom}
        onCancel={() => {
          setVisibleCustom(false);
        }}
        onOk={() => {
          setVisibleCustom(false);
          completeTask(customTask);
        }}
      >
        <strong style={{fontSize: '20px'}}>Complete Task?</strong>
      </Modal>
      <CRUDDataTable
        fetchDataFunction={async (params) => {
          params = {
            ...params,
            sortColumn: 'createdAt',
            sortDirection: 'DESC',
            status: 'NEW'
          }
          return await getPatientTasks(params);
        }}
        entity='Tasks'
        button={null}
        showTitle={false}
        columns={cols}
        highlightRow={(record, i) => record.status === 'NEW'}
        draw={draw}
      />
    </>
  )

}

export default Tasks;