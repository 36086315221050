import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "hooks/use-app-selector";
import {
  fetchAppointmentsBySpan,
  confirmAppointmentStatus,
  declineAppointmentStatus,
  clearAppointments,
  updateAppointmentsParams
} from "redux/actions/appointment";
import { startTelehealthMeeting } from "redux/actions/zoom";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { List, Spin, Tag } from "antd";
import { Row, Col } from "react-bootstrap";
import AppointmentCard from "components/Appointments/AppointmentCard";
import ConfirmationModal from "components/Appointments/Modals/ConfirmationModal";
import DeclineModal from "components/Appointments/Modals/DeclineModal";
import {
  PAST,
  UPCOMING_APPOINTMENTS,
  PAST_APPOINTMENTS,
} from "constants/appointment-constants";
import { AssignmentTurnedIn, ListAlt } from "@material-ui/icons";
import { BsFillCameraVideoFill, AiFillCloseCircle } from "react-icons/all";
import { AppointmentStatuses } from "enums/appointment-statuses";
import { AppointmentAttendanceTypes } from "enums/appointment-attendance-types";
import { APPOINTMENTS_PERMISSIONS } from "redux/types/permissions-types/appointments-permissions";
import { fetchTenantUpcomingAppointmentsExtraOptions } from "../../redux/actions/tenants";
import {
  ConfirmationNumber
} from '@material-ui/icons';
import { DoubleRightOutlined } from '@ant-design/icons';
import moment from 'moment';

interface AppointmentsModuleProps {
  span: string;
}

const AppointmentsModule: FunctionComponent<AppointmentsModuleProps> = ({
  span,
}) => {
  const [activeAppointmentId, setActiveAppointmentId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const appointments = useAppSelector((state) => state.appointment);
  const permissions = useAppSelector((state) => state.permissions.permissions);
  const isConfirmationButtonLoading = useAppSelector(state => state.appointment.isConfirmationButtonLoading);
  const pageSize = 6;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchAppointments = useCallback(
    (page: number) => {
      const sortDirection = span === PAST ? "DESC" : "ASC";
      setPageNumber(page);
      dispatch(
        fetchAppointmentsBySpan(span, {
          length: pageSize,
          page: page - 1,
          sortDirection: sortDirection,
          sortColumn: "appointmentDateTime",
          currentDate: moment.utc().format("YYYY-MM-DD HH:mm:ss").toString()
        })
      );
    },
    [span, dispatch]
  );

  useEffect(() => {
    fetchAppointments(1);
  }, [dispatch, span, fetchAppointments]);

  useEffect(() => {
    dispatch(clearAppointments())
  },[])

  useEffect(() => {
    dispatch(updateAppointmentsParams({
      currentPageNumber: 0,
      length: pageSize,
      span: span,
      sortColumn: "appointmentDateTime",
      sortDirection: span === PAST ? "DESC" : "ASC"
    }));
  },[span]);
  /** VIEWING APPOINTMENT */
  const ViewDetails = (id: any, ticketNumber: string) => {
    const spanLink = span === PAST ? PAST_APPOINTMENTS : UPCOMING_APPOINTMENTS;
    return <div className={`d-flex align-items-center ${ticketNumber ? 'justify-content-between' : 'justify-content-end'}`}>
      {ticketNumber && <div className="Appointment-Ticket-Number">
        <div style={{fontSize: '12px'}}>Ticket Number</div>
        <div style={{fontSize: '14px', fontWeight: 'bold'}}>{ticketNumber}</div>
      </div>}
      <Link to={`/${spanLink}/${id}`} style={{paddingRight: '15px'}}>
        {/* <ListAlt fontSize="large" />{" "} */}
        <span className="d-block d-lg-block">
          {t("label.view_details")} <DoubleRightOutlined className="Double-Right-Icon"/>
        </span>
      </Link>
    </div>;
  };

  /** CONFIRMING APPOINTMENT */
  const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
  const ConfirmAppointment = (id: React.SetStateAction<number>) => (
    <div
      onClick={() => {
        setActiveAppointmentId(id);
        setConfirmModalVisibility(true);
      }}
    >
      <AssignmentTurnedIn fontSize="large" />{" "}
      <span className="d-block d-lg-block font-weight-bold">
        {t("label.confirm")}
      </span>
    </div>
  );
  const onConfirmationClick = () => {
    setConfirmModalVisibility(false);
    dispatch(confirmAppointmentStatus(activeAppointmentId));
  };

  /** DECLINING APPOINTMENT */
  const [isDeclineModalVisible, setDeclineModalVisibility] = useState(false);
  const DeclineAppointment = (id: React.SetStateAction<number>) => (
    <div
      className="decline-button"
      onClick={() => {
        setActiveAppointmentId(id);
        setDeclineModalVisibility(true);
      }}
    >
      <AiFillCloseCircle style={{ fontSize: "3.5rem" }} />{" "}
      <span className="d-block d-lg-block font-weight-bold">
        {t("label.decline")}
      </span>
    </div>
  );
  const onDeclineClick = () => {
    setDeclineModalVisibility(false);
    dispatch(declineAppointmentStatus(activeAppointmentId));
  };

  /** TELEHEALTH */
  const StartMeeting = (appointmentId: any) => (
    <div onClick={() => onStartMeetingClick(appointmentId)}>
      <BsFillCameraVideoFill size={"2.5em"} />{" "}
      <span className="d-block d-lg-block font-weight-bold">
        {t("label.start_telehealth")}
      </span>
    </div>
  );
  function onStartMeetingClick(appointmentId: any) {
    dispatch(startTelehealthMeeting(appointmentId));
  }

  return (
    <>
      <ConfirmationModal
        visible={isConfirmModalVisible}
        onOk={onConfirmationClick}
        onCancel={() => setConfirmModalVisibility(false)}
      />
      <DeclineModal
        visible={isDeclineModalVisible}
        onOk={() => onDeclineClick()}
        onCancel={() => setDeclineModalVisibility(false)}
      />
      <Spin spinning={appointments.isListPageLoading}>
        <Spin spinning={isConfirmationButtonLoading} size="large" tip="Confirming appointment...">
          <List
            className={"Appointments-List"}
            grid={{
              gutter: 20,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 3,
            }}
            pagination={{
              total: appointments?.recordsTotal,
              pageSize: pageSize,
              onChange: (page) => {
                dispatch(updateAppointmentsParams({
                  currentPageNumber: page - 1,
                  length: pageSize,
                  span: span,
                  sortColumn: "appointmentDateTime",
                  sortDirection: span === PAST ? "DESC" : "ASC"
                }));
                fetchAppointments(page);
              },
            }}
            dataSource={appointments.list}
            renderItem={(item, i) => {
              const { id, ticketNumber } = item;
              const isConfirmed = item.status === AppointmentStatuses.Confirmed;
              const isDeclined = item.status === AppointmentStatuses.Declined;

              let ActionLayout = [ViewDetails(id, ticketNumber)];

              if (permissions.Appointments?.includes("appointment:confirm")) {
                if (!isConfirmed && span !== PAST) {
                  ActionLayout = [ViewDetails(id, ticketNumber), ConfirmAppointment(id)];
                }
              }

              if (isConfirmed && span !== PAST) {
                ActionLayout = [ViewDetails(id, ticketNumber)];
              }

              if (permissions.Appointments?.includes("appointment:confirm")) {
                if (
                  item.attendanceType === AppointmentAttendanceTypes.Telehealth &&
                  span !== PAST &&
                  !isConfirmed
                ) {
                  ActionLayout = [ViewDetails(id, ticketNumber), ConfirmAppointment(id)];
                }
              }
              if (permissions.Appointments?.includes("appointment:telehealth")) {
                if (
                  item.attendanceType === AppointmentAttendanceTypes.Telehealth &&
                  span !== PAST &&
                  isConfirmed
                ) {
                  ActionLayout = [ViewDetails(id, ticketNumber), StartMeeting(id)];
                }
              }

              if (permissions.Appointments?.includes("appointment:decline")) {
                if (span !== PAST && !isConfirmed) {
                  ActionLayout = [...ActionLayout, DeclineAppointment(id)];
                }
              }

              if (isDeclined) {
                ActionLayout = [ViewDetails(id, ticketNumber)];
              }

              return (
                <List.Item>
                  <AppointmentCard
                    ActionLayout={ActionLayout}
                    item={item}
                    span={span}
                    pageNumber={pageNumber}
                  />
                </List.Item>
              );
            }}
          />
        </Spin>
      </Spin>
    </>
  );
};

export default AppointmentsModule;
