import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

const logoutApi = createAxios({
    baseURL: API_BASE_URL + '/patient-login'
});

export const logout = (payload) => {
    return logoutApi.post('/logout', payload);
}
