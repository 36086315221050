import {
    SET_CURRENT_USER,
    SET_CURRENT_USER_STATUS,
    SHOW_ACCOUNT_SETTINGS,
    SET_CURRENT_USER_PREFERENCE
} from './types/currentUser';

export function setCurrentUser(data) {
    return {
        type: SET_CURRENT_USER,
        payload: data
    };
}

export function setCurrentUserLocationStatus(data) {
    return {
        type: SET_CURRENT_USER_STATUS,
        payload: data
    };
}

export function setCurrentUserLocationPreferences(data) {
    return {
        type: SET_CURRENT_USER_PREFERENCE,
        payload: data
    };
}

export function showAccountSettings(status) {
    return {
        type: SHOW_ACCOUNT_SETTINGS,
        payload: status
    };
}
