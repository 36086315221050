import React, {useEffect, useState} from 'react';
import {Modal, Table, Empty} from 'antd';
import {FileTextOutlined} from '@ant-design/icons';

import 'styles/Attachments.scss';
import {Link} from "react-router-dom";
import styled from 'styled-components';
import {fetchAppointmentFormsDataTable} from "api/formSubmissions";
import {useParams} from "react-router";
import {useMediaQuery} from "@material-ui/core";
import {useTheme} from '@material-ui/core/styles';
import FormSubmitModal from "./FormSubmitModal";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {useLocation} from "react-router-dom";

import {
    CheckCircle
} from '@material-ui/icons';

import 'styles/RowHighlights.scss';

const StyledModal = styled(Modal)`
    & .ant-modal-body {
        max-height: 600px !important;
        overflow: auto !important;
    }

    @media(max-width: 991px) {
        width: 100% !important;
    }
`

const StyledTable = styled(Table)`
    width: 100%;
    background: #fff;
    border-radius: 10px;
    & .ant-spin-nested-loading,
    & .ant-spin-container {
        height: 100%;
    }

    & .ant-table {
        height: 100%;
        border-radius: 10px;
    }

    & .ant-pagination {
        position: relative;
        margin: 16px;
    }

    & .anticon-file-text {
        vertical-align: unset;
    }
`

function FormList(props) {
    const { span } = props;
    const [isFormModalVisible, setFormModalVisibility] = useState(false);
    const [formEntity, setFormEntity] = useState(null);
    const [title, setTitle] = useState();
    const [forms, setForms] = useState([]);
    const [activeSubmissionFormId, setActiveSubmissionFormId] = useState(null);
    const [activeFormDesignId, setActiveFormId] = useState(null);
    const [isEditorModalLoading, setIsEditorModalLoading] = useState(false);
    const [willFormListRefresh, setWillFormListRefresh] = useState(false);
    const [draw, setDraw] = useState(0);
    const [formStatus, setFormStatus] = useState(null);
    const [notificationModal, setNotificationModal] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const FORM_PAGE_SIZE = 5;
    const search = useLocation().search;
    const formId = new URLSearchParams(search).get('form-id');
    const formDesignId = new URLSearchParams(search).get('form-design-id');
    const formName = new URLSearchParams(search).get('name');

    const checkIsAppointment = window.location.pathname.includes("past-appointments") || window.location.pathname.includes("upcoming-appointments");
    
    useEffect(() => {
        if(checkIsAppointment && formId && formDesignId) {
            setActiveSubmissionFormId(formId);
            setActiveFormId(formDesignId);
            setTitle(formName);
            setFormStatus(new URLSearchParams(search).get('status'))
            setFormModalVisibility(true);
            setNotificationModal(true);
        }
    }, [checkIsAppointment, formId, formDesignId, formName]);
    
    const { t } = useTranslation();

    const col = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => <Link onClick={() => {
                setActiveFormId(record.formDesign.id);
                setActiveSubmissionFormId(record.id);
                setTitle(record.name);
                setFormModalVisibility(true);
                setFormStatus(record.status);
                setNotificationModal(false);
            }}>
                <div className="d-flex">
                    {text}
                    {
                        record.status === 'COMPLETE' &&
                        <CheckCircle fontSize="small" style={{ color: "#4caf50" }} className="pl-2" />
                    }
                </div>
                
                <small 
                    className={'font-weight-normal text-muted'}
                    style={{ marginLeft: '17px' }}
                >
                    {moment.utc(record.lastModifiedAt).local().format('DD/MM/YYYY hh:mm A')}  
                </small>
            </Link>,
        }
    ]

    const param = useParams();

    useEffect(() => {
        (async () => {
            let { data } = await fetchAppointmentFormsDataTable(param.id, {
                draw: 1,
                page: 0,
                length: 10000,   // temp
                sortColumn: 'lastModifiedAt',
                sortDirection: 'DESC'
            });

            setForms(data.data.map(elem => elem));
        })();
    }, [param]);

    useEffect(() => {
        if (draw) {
            (async () => {
                let { data } = await fetchAppointmentFormsDataTable(param.id, {
                    draw: draw,
                    page: 0,
                    length: 10000,   // temp
                    sortColumn: 'lastModifiedAt',
                    sortDirection: 'DESC'
                });
    
                setForms(data.data.map(elem => elem));
            })();
        }
    }, [draw]);

    const locale = {
        emptyText: <Empty description={t('message.no_form')} style={{color:'#000000D9'}}/>,
    };

    return (
        <>
            <FormSubmitModal
                visible={isFormModalVisible}
                setVisible={setFormModalVisibility}
                fullScreen={fullScreen}
                activeSubmissionId={activeSubmissionFormId}
                designId={activeFormDesignId}
                formStatus={formStatus}
                name={title}
                formDraw={draw}
                setFormDraw={setDraw}
                notificationModal={notificationModal}
                span={span}
            />

            <StyledTable
                columns={col}
                pagination={{
                    pageSize: FORM_PAGE_SIZE,
                }}
                locale={locale}
                dataSource={forms.map((form, i) =>
                    (
                        {
                            ...form,
                            name: <>
                                    <div key={i}>
                                        <FileTextOutlined /> { form.formDesign.name }<br/>
                                    </div>
                                </>
                        }
                    )
                )}
                rowClassName={(record, i) =>
                    record.highlight? 'Row-Highlight': 'Row-Unhighlight'}
            />
        </>
    );
}

export default FormList;