import React, { useState } from "react";
import { useNode, useEditor } from '@craftjs/core';
import { Input as AntInput, InputNumber, Radio } from 'antd';

import { 
    Container,
    Row,
    Col
} from 'react-bootstrap';

import ContentEditable from 'react-contenteditable';

const Text = ({
    text = 'Enter Text Here', 
    fontSize, 
    textAlign = 'left',
    fontStyle = 'regular',
    textColor = '#404040'
}) => {
    const { 
        connectors: { connect, drag },
        actions: { setProp } 
    } = useNode((state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged
    }));

    const { query } = useEditor();

    const [editable, setEditable] = useState(false);

    const fontStyleValue = fontStyle === 'italic' ? 'italic' : 'unset';

    let fontWeight = '500';

    if (fontStyle === 'bold') {
        fontWeight = '700'
    } 

    return (
        <div
            className={query.getOptions().enabled && 'With-Mark'}
            ref={ref => connect(drag(ref))}
            onClick={e => setEditable(true)}
            style={{
                marginTop: '10px',
                marginBottom: '10px'
            }}
        >
            <ContentEditable
                disabled={true}
                html={text}
                onChange={e =>
                    setProp(props => 
                        props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, "")
                    )
                }
                tagName='p'
                style={{
                    fontSize: `${fontSize}px`,
                    marginBottom: '0',
                    textAlign,
                    fontStyle: fontStyleValue,
                    fontWeight,
                    color: textColor
                }}
            />
        </div>
    )
};

const TextSettings = () => {
    const { actions: { setProp }, fontSize, textAlign, fontStyle, textColor } = useNode((node) => ({
        fontSize: node.data.props.fontSize,
        textAlign: node.data.props.textAlign,
        fontStyle: node.data.props.fontStyle,
        textColor: node.data.props.textColor
    }));

    return (
        <>
            <Row>
                <Col xs={6}>
                    <label className='Form-Builder-Label'>Font Size:</label>
                    <AntInput
                        className='text-center'
                        defaultValue={fontSize}
                        onChange={(e) => {
                            setProp(props => props.fontSize = e.target.value);
                        }}x
                        addonAfter='px'
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <label className='Form-Builder-Label'>Font Style:</label><br/>
                    <Radio.Group
                        defaultValue={fontStyle}
                        buttonStyle="solid"
                        onChange={e => {
                            setProp(props => props.fontStyle = e.target.value);
                        }}
                    >
                        <Radio.Button value="regular">Regular</Radio.Button>
                        <Radio.Button value="bold">
                            <strong>Bold</strong>
                        </Radio.Button>
                        <Radio.Button value="italic">
                            <i>Italic</i>
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <label className='Form-Builder-Label'>Text Color:</label><br/>
                    <AntInput
                        defaultValue={textColor ? textColor : '#404040'}
                        onChange={(e) => {
                            setProp(props => props.textColor = e.target.value);
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <label className='Form-Builder-Label'>Text Alignment:</label><br/>
                    <Radio.Group
                        defaultValue={textAlign}
                        buttonStyle="solid"
                        onChange={e => {
                            setProp(props => props.textAlign = e.target.value);
                        }}
                    >
                        <Radio.Button value="left">Left</Radio.Button>
                        <Radio.Button value="center">Center</Radio.Button>
                        <Radio.Button value="right">Right</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </>
    )
};

Text.craft = {
    props: {
        text: 'Enter Text Here',
        fontSize: 20,
        textAlign: 'left',
    },
    related: {
        settings: TextSettings
    }
}

export default Text;