import { 
    getNotificationsByPatientId,
    markNotificationAsRead,
    getUnreadNotificationsCount,
    setNotificationAsReadById,
    markNotificationsAsClear,
    sendNotificationsAck
} from 'api/notifications';

import {
    FETCH_NOTIFICATIONS_LIST_ERROR,
    FETCH_NOTIFICATIONS_LIST_REQUEST,
    FETCH_NOTIFICATIONS_LIST_SUCCESS,
    FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS,
    FETCH_NOTIFICATIONS_LIST_TALLY_ERROR,
    MARK_NOTIFICATION_READ_SUCCESS,
    MARK_NOTIFICATION_READ_ERROR,
    MARK_NOTIFICATION_CLEAR_SUCCESS,
    MARK_NOTIFICATION_CLEAR_ERROR,
    SET_NOTIFICATIONS_UNREAD_COUNT,
    MARK_NOTIFICATION_READ_BY_ID_SUCCESS,
    MARK_NOTIFICATION_READ_BY_ID_ERROR
} from 'redux/actions/types/notifications';

export function setNotificationsUnReadCount(count) {
    return {
        type: SET_NOTIFICATIONS_UNREAD_COUNT,
        payload: count
    };
}

export const fetchNotificationsListByUserId = (userId, currentPage = 0, pageSize = 0) => {
    sendNotificationsAck();
    
    return async dispatch => {
        try {
            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_REQUEST
            });

            let response;

            if (currentPage !== 0 && pageSize !== 0) {
                response = await getNotificationsByPatientId(userId, `&page=${currentPage}&length=${pageSize}`);
            }
            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_SUCCESS,
                payload: response ? response.data.data : [] ,
            });
        } catch (error) {
            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_ERROR,
                error
            });
        }
    }
};

export const setNotificationAsRead = () => {
    return async dispatch => {
        try {
            const response = await markNotificationAsRead();

            dispatch({
                type: MARK_NOTIFICATION_READ_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: MARK_NOTIFICATION_READ_ERROR,
                error,
            });
        }
    }
};

export const setNotificationAsClear = () => {
    return async dispatch => {
        try {
            const response = await  markNotificationsAsClear();
            dispatch({
                type: MARK_NOTIFICATION_CLEAR_SUCCESS,
            });
        } catch (error) {
            dispatch({
                type: MARK_NOTIFICATION_CLEAR_ERROR,
                error,
            });
        }
    }
};

export const fetchNotificationsTallyByUserId = (userId) => {
    return async dispatch => {
        try {
            const response = await getUnreadNotificationsCount(userId);

            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_TALLY_ERROR,
                error
            });
        }
    };
};

export const markNotificationAsReadById = id => {
    return async dispatch => {
        try {
            const response = await setNotificationAsReadById(id);

            dispatch({
                type: MARK_NOTIFICATION_READ_BY_ID_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: MARK_NOTIFICATION_READ_BY_ID_ERROR,
                error,
            });
        }
    }
};