import { API_BASE_URL } from './config';
import {createAxios} from "./axios";

const jwtSettings = createAxios({
    baseURL: API_BASE_URL + '/settings'
})

export const getUserTimeout = () => {
    return jwtSettings.get('/JWT-property');
}

