import React, { useEffect, useState } from "react";
import AppointmentSection from "components/Appointments/AppointmentSection";
import AppointmentInfo from "components/Appointments/AppointmentInfo";
import AttachmentList from "components/Appointments/AttachmentList";
import CollateralList from "components/Collateral/CollateralList";
import MessageList from "components/Appointments/MessageList";
import TaskList from "components/Appointments/TaskList";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Tag } from "antd";
import { BsFillCameraVideoFill } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { fetchAppointmentRequest } from "redux/actions/appointment";
import { setPageTitle, setBreadcrumbsText } from "redux/actions/ui";
import { startTelehealthMeeting } from "redux/actions/zoom";

import { useTranslation } from "react-i18next";
import FormList from "components/Appointments/FormList";
import { useAppSelector } from "./../../../hooks/use-app-selector";
import { APPOINTMENTS_PERMISSIONS } from "redux/types/permissions-types/appointments-permissions";
import MapImage from "components/Map/MapImage";
import AppointmentTypeTag from 'components/Appointments/AppointmentTypeTag'
import { fetchNotificationsListByUserId, markNotificationAsReadById } from "redux/actions/notifications";

function View(span) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const { isLoading, data: appointment, error } = useSelector(
    (state) => state.appointment
  );
  const { isTeleHealthButtonLoading } = useSelector((state) => state.zoom);
  const { messages } = appointment;
  const notificationState = useSelector(state => state.notifications);
  const { tally } = notificationState;

  const [taskId, setTaskId] = useState("");
  const [refreshTask, setRefreshTask] = useState(0);
  const notificationList = useAppSelector(state => state.notifications.list);

  useEffect(() => {
    dispatch(fetchAppointmentRequest(id));
    dispatch(setBreadcrumbsText(appointment.appointmentType));    

    if (modulePermissions.includes("appointment:telehealth")) {
        dispatch(setPageTitle(appointment.appointmentType))
    } else {
        dispatch(setPageTitle(" "))
    }

    return () => {
      dispatch(setPageTitle(""));
      dispatch(setBreadcrumbsText(""));
    };
  }, [id, dispatch, appointment.appointmentType]);

//   useEffect(() => {
//     dispatch(fetchNotificationsListByUserId(0, 1, -1));
//   }, []);

  useEffect(() => {    
    handleNotificationCount();    
  }, [notificationList]);

  const onStartAppointmentClick = () => {
    dispatch(startTelehealthMeeting(appointment.id));
  };

  const modulePermissions = useAppSelector(
    (state) => state.permissions.permissions.Appointments
  );

  const handleNotificationCount = () => {   
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const form_id = urlParams.get('form-id');
      if (notificationList?.length > 0) {
        if (form_id) {
          let notificationData = notificationList?.find(x => x.formSubmission?.id === parseInt(form_id));        
          dispatch(markNotificationAsReadById([notificationData?.id]));
        }
        else {
          let notificationData = notificationList?.filter(x => x.appointment?.id === parseInt(id));
          // @TODO @see https://bitbucket.org/5faces/%7Bb39230d1-762b-451f-83c6-e34f2bb89a1b%7D/pull-requests/300
          let notificationIds = notificationData.map(function (p) { return p.id; });          
          dispatch(markNotificationAsReadById(notificationIds));
        }
      }      
  }

  return (
    <>
      <Container fluid>
        <Row>
          {/* <Permission permissions={modulePermissions} permissionKey={APPOINTMENTS_PERMISSIONS['appointment:telehealth']}> */}
          {modulePermissions.includes("appointment:telehealth") ? (
            <>
              {appointment.attendanceType === "TELEHEALTH" && (
                <Col xs={12} md={6} style={{ marginBottom: "20px" }}>
                  <Button
                    className="Floating-Call-Button mb-4"
                    type="primary"
                    onClick={() => onStartAppointmentClick()}
                    loading={isTeleHealthButtonLoading}
                  >
                    <BsFillCameraVideoFill
                      style={{ marginTop: "-5px" }}
                      size={"2em"}
                    />
                    &nbsp;&nbsp;{t("label.start_telehealth")}
                  </Button>
                </Col>
              )}
            </>
          ) : <Col xs={12} md={6}>
                {/* <h1 className='fivef-pageTitle mt-3 ml-0' style={{ fontSize: '3.5rem' }}>
                    {appointment.appointmentType}
                </h1> */}
            </Col>}
          {/* </Permission> */}
          <Col
            className="Contact-Cancellation"
            xs={12}
            md={6}
            // md={appointment.attendanceType === "TELEHEALTH" && modulePermissions.includes("appointment:telehealth") ? 6 : 12}
          >
            
            { // Commented this code as per Ticket TDPJ-320
            /* <h3 className="Appointment-Detail-Title">
              For Cancellations contact:{" "}
              <a href="tel:07 3232 7202">
                <strong>07 3232 7202</strong>
              </a>
            </h3> */}
          </Col>
        </Row>
        <AppointmentSection
          title={t("label.appointment_info")}
          ticketNumber={appointment?.ticketNumber}
          barcode={appointment?.barcode}
          displayBarcode={appointment?.displayBarcode}
          showQrCode={true}
        >
            <AppointmentTypeTag type={appointment.appointmentType} style={{ position: 'relative', top: '-15px' }} />
            <AppointmentInfo appointment={appointment} />
        </AppointmentSection>
        <Row>
          {modulePermissions.includes('appointment:tasks') && <Col xs={12} sm={12} md={12} lg={6} flex={1}>
            <AppointmentSection title={t("label.task_plural")}>
              <TaskList
                setTaskId={setTaskId}
                refreshTask={refreshTask}
                setRefreshTask={setRefreshTask}
                appointmentId={appointment.id}
              />
            </AppointmentSection>
          </Col>}
          {modulePermissions.includes('appointment:messages') && <Col xs={12} sm={12} md={12} lg={6} flex={1}>
            <AppointmentSection title={t("label.message_plural")}>
              <MessageList messages={messages} appointmentId={appointment.id} />
            </AppointmentSection>
          </Col>}
          <Col xs={12} sm={12} md={12} lg={6} flex={1}>
          <AppointmentSection title={t("label.form_plural")}>
              <FormList
                appointment={appointment}
                attachments={appointment.attachments}
                span={span.span}
              />
            </AppointmentSection>
          </Col>
          {modulePermissions.includes('appointment:attachments') && <Col xs={12} sm={12} md={12} lg={6}>
            <AppointmentSection title={''}>
              <AttachmentList
                appointment={appointment}
                attachments={appointment.attachments}
                taskId={taskId}
                setTaskId={setTaskId}
                setRefreshTask={setRefreshTask}
              />
            </AppointmentSection>
          </Col>}
        {modulePermissions.includes('appointment:collaterals') && <Col xs={12} sm={12} md={12} lg={6}>
            <AppointmentSection title={t("label.collateral")}>
              <CollateralList
                collaterals={appointment.collaterals}
                appointmentId={appointment.id}
              />
            </AppointmentSection>
          </Col>}
         { appointment?.clinicMap && (appointment?.appointmentType && appointment?.appointmentType?.toLowerCase() !== 'telephonic' && appointment?.appointmentType?.toLowerCase() !== 'telehealth') && <Col xs={12} sm={12} md={12} lg={6}>
            <AppointmentSection title='Map'>
              <MapImage clinicMapUrl={appointment?.clinicMap} />
            </AppointmentSection>
          </Col>}
        </Row>
      </Container>
    </>
  );
}

export default View;
