import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import 'styles/Messages.scss';
import 'styles/RowHighlights.scss';

import { List, Avatar, Spin, Tag } from 'antd';
import { MailOutline, Drafts } from '@material-ui/icons';
import { Container, Row, Col } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import parser from 'html-react-parser';
import {
    fetchNotificationsListByUserId,
    markNotificationAsReadById,    
} from 'redux/actions/notifications';
import {
    fetchMessagesByUserId,
} from 'redux/actions/messages';
import {
    setCurrentUser
} from 'redux/actions/currentUser';

import { handleResponsive } from 'helpers/utils';
import { getUser } from 'api/users';

const Inbox = () => {
    const [paginationPosition, setPaginationPosition] = useState('bottom');
    const userId = 0;
    const defaultPage = 0;
    const defaultPageSize = 10;

    const history = useHistory();
    const messageState = useSelector(state => state.messages);
    const dispatch = useDispatch();

    const { list, totalElements, isInboxListLoading } = messageState;
    const messageList = list;
    const currentUser = useSelector(state => state.currentUser.data);
    const notificationList = useSelector(state => state.notifications.list);  
    const notificationState = useSelector(state => state.notifications);
    const { tally } = notificationState; 
    
    // useEffect(() => {
    //     dispatch(fetchNotificationsListByUserId(0, 1, -1));
    // }, []);    

    useEffect(() => {
        
        handleResponsive({
            xsCallback: () => setPaginationPosition('top'),
            lgCallback: () => setPaginationPosition('bottom')
        });

        if(currentUser) {
            dispatch(fetchMessagesByUserId(currentUser?.id, defaultPage, defaultPageSize));
        } else {
            fetchCurrentUser();
        }
    }, [userId, dispatch, currentUser]);

    const fetchCurrentUser = async () => {
        const res = await getUser();
        dispatch(setCurrentUser(res.data)); 
    }

    const handleMessageListItemClick = (id) => {      
        let notificationData = notificationList?.find(x => x.appointmentMessage?.id === id);       
        dispatch(markNotificationAsReadById([notificationData?.id]));
        history.push(`/inbox/${id}`);
    };

    return (
        
        <Container fluid>
            <Row>
                <Col>
                    <Spin spinning={isInboxListLoading}>
                        <List
                            className='Inbox-List-Container'
                            itemLayout='horizontal'
                            dataSource={messageList}
                            pagination={{
                                position: paginationPosition,
                                total: totalElements,
                                pageSize: defaultPageSize,
                                onChange: (page, pageSize) => {
                                    dispatch(fetchMessagesByUserId(currentUser?.id, page - 1, defaultPageSize));
                                },
                            }}
                            showSizeChanger
                            renderItem={(message, i) => {                                
                                const newMessageHTML = message.message?.replaceAll(/<p>|<\/p>/g, '');
                                
                                return <List.Item
                                            className={`Inbox-Item ${ message.highlight? 'Row-Highlight': 'Row-Unhighlight' }`}
                                            extra={<span>{moment.utc(message.createdAt).local().format('DD/MM/YY')}</span>}
                                            onClick={() => handleMessageListItemClick(message.id)}
                                        >
                                            <List.Item.Meta
                                                avatar={<Avatar
                                                    size='large'
                                                    icon={message.status === 'READ' ? <Drafts /> : <MailOutline />}
                                                    style={{
                                                        backgroundColor: '#74bbf8'
                                                    }}
                                                />}
                                                title={<Link to={'/inbox'}>
                                                        <h4>
                                                            <small style={{ fontSize: '1rem' }} className='font-weight-bold text-muted'>SUBJECT</small><br/>
                                                            {message.replyingToMessage? 'Re: ': null}
                                                            {message.subject}&nbsp;
                                                            <small style={{ fontSize: '.5rem' }}>
                                                            {/* { message.sender === 'STAFF' ?
                                                                <Tag color='rgb(16, 142, 233)'>Incoming</Tag>
                                                                :
                                                                <Tag color='rgb(0, 200, 83)'>Outgoing</Tag>
                                                            } */}
                                                            </small>
                                                        </h4>
                                                        <div>
                                                        </div>
                                                    </Link>}
                                                description={parser(newMessageHTML)}
                                            />
                                        </List.Item>
                            }}
                        />
                    </Spin>
                </Col>
            </Row>
        </Container>
    );
};

export default Inbox;