import React, { useState, useRef } from 'react';
import { PaperClipOutlined } from '@ant-design/icons';

import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import {Button} from "antd";

export const AttachButton = styled(Button)`
  min-height: unset;
  color: #0170FE;
  background-color: #fff;
  padding: 15px 20px;
  min-width: unset;
  line-height: inherit;
  height: auto;
  font-weight: inherit !important;
  & span {
    font-size: inherit;
    font-weight: inherit;
    margin-left: 0 !important;
  }
`

const FileUploader = ({ 
    field, 
    form: { touched, errors, setFieldValue, getFieldValue }, 
    onChange, 
    ...props 
}) => {
    const [fileName, setFileName] = useState('');
    const inputFile = useRef(null);

    const handleFileUploadClick = () => {
        inputFile.current.click();
    };

    const handleFileUploadChange = event => {
        const fileUploaded = event.target.files[0];
        setFileName(fileUploaded.name);

        setFieldValue(field.name, fileUploaded);
    };
 
    return (
        <>
            <input type='file' 
                    style={{ display: 'none' }} 
                    onChange={handleFileUploadChange}
                    ref={inputFile} />
            <AttachButton
                className='File-Uploader-Button'
                onClick={handleFileUploadClick}
            >
                <PaperClipOutlined style={{ fontSize: '2rem' }} />
                Attach a file
            </AttachButton>
            <div className='File-Upload-List'>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={12}>
                            {fileName}
                        </Col>
                        {touched[field.name] && errors[field.name] && (
                            <p className='text-danger Error-Text'>{errors[field.name]}</p>
                        )}
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default FileUploader;