import React, { useEffect, useState } from 'react';
import DynamicForm from 'components/DynamicForm';
import { useSelector } from 'react-redux';
import { registerPatient, validateToken } from 'api/patientRegistration';
import { Button, Skeleton } from 'antd';
import { message } from 'helpers/notificationhelper';
import TokenError from './TokenError';
import { FieldComponent, MessageTypes } from 'interfaces';
import { RootState } from 'redux/reducers';
import { REGISTRATION_TYPE } from 'redux/types/tenant-types';
import MessagePage from 'components/MessagePage';

interface InviteSignupProps {
    match: any
}

export default (props: InviteSignupProps) => {
    const { match } = props;
    const token = match.params.token;
    const [isTokenValid, setTokenValidity] = useState(false);
    const [isUserExists, setUserExistence] = useState(false);
    const [isPageLoading, setPageLoading] = useState(true);
    const tenantState = useSelector((state:RootState) => state.tenant);
    const isRegistrationPageNotInvite = tenantState?.registration?.type as REGISTRATION_TYPE !== REGISTRATION_TYPE.INVITE;
    const REQUIRE_REGISTRATION_TOKEN = !false;

    useEffect(() => {
        (!token || REQUIRE_REGISTRATION_TOKEN) && setPageLoading(false)
        token && validateToken(token)
            .then((response) => {
                const tokenValidity = response.data.data;
                setPageLoading(false);
                setTokenValidity(tokenValidity);
            })
            .catch(error => {
                const errorResponse = error.response.data;
                setPageLoading(false);
                console.log(errorResponse.data);

                if (error.response.status === 409) {
                    setUserExistence(true);
                }

                if (errorResponse.data?.type === 'TOKEN_EXPIRED') {
                    setPageLoading(false);
                    setTokenValidity(false);
                }
            });
    }, []);

    return  <>
                
                <Skeleton
                    active={isPageLoading}
                    loading={isPageLoading}
                />
                {!isPageLoading 
                    && ((isTokenValid 
                    && !isRegistrationPageNotInvite) || (!token && REQUIRE_REGISTRATION_TOKEN))
                    && <DynamicForm
                        fields={tenantState?.registration?.fields as FieldComponent[]}
                        hasTermsAndConditions
                        onSubmit={(values: any) => {
                            let selectedDate = values.dob.split(/\//);
                            const payload = {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                dateOfBirth: selectedDate[2] +'-'+ selectedDate[1] +'-'+ selectedDate[0],
                                mobileNumber: values.mobileNo,
                                medicareNumber: values.medicareNo + values.irn,
                                dva: values.dva,
                                sex: values.sex
                            };

                            registerPatient(token ?? '', payload)
                                .then((response) => {
                                    message.success('Successfully registered.');

                                    const redirectTimeout = setTimeout(() => {
                                        window.location.href = '/';
                                        if (redirectTimeout) {
                                            clearTimeout(redirectTimeout);
                                        }
                                    }, 3000);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    const errorResponse = error.response;

                                    if (error.response.status === 409) {
                                        message.error(error.response.data?.message);
                                    }
                                    
                                    if (errorResponse?.data?.data?.type === 'TOKEN_EXPIRED') {
                                        setTokenValidity(false);
                                    }
                                });
                        }}
                    />}
                    {token && !isPageLoading 
                        && !isTokenValid 
                        && isUserExists
                        && !isRegistrationPageNotInvite && 
                        <>
                            <MessagePage
                                type={MessageTypes.INFO}
                                headingText={'Already Registered'}
                                body={
                                    <>
                                        <span>User has already been registered.</span><br/><br/>
                                        <Button type='primary' onClick={() => {
                                            window.location.href = '/';
                                        }}>Go back</Button>
                                    </>
                                }
                            />
                        </>
                    }
                    {token && !isPageLoading 
                        && !isTokenValid 
                        && !isUserExists
                        && !isRegistrationPageNotInvite && <TokenError />}
                    {(!isPageLoading && isRegistrationPageNotInvite) || (!token && !REQUIRE_REGISTRATION_TOKEN)
                        && <MessagePage
                            type={MessageTypes.WARNING}
                            headingText={'Unauthorized'}
                            body={'Sorry, You are not authorized to access this page.'}
                        />}
            </>
}