import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import styles from "./LoginModule.module.scss";
import { Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { Link } from "react-router-dom";
import defaultLogo from "assets/images/5f-dark-logo.png";
import { useAppSelector } from "./../../hooks/use-app-selector";
import { AUTHENTICATION_TYPES } from "redux/types/tenant-types";
import MobileAuthenticationComponent from "./components/MobileAuthenticationComponent";
import OTPComponent from "./components/OTPComponent";
import { Switch } from "react-router";
import { message, notification } from 'antd';
import { getBrandingLogoByTenant } from 'api/branding';
import LoginMessage from "../../components/LoginMessage";

interface LoginModuleProps {
  keycloak: any;
}

const LoginModule: FunctionComponent<LoginModuleProps> = ({ keycloak }) => {
  const login = () => keycloak.login();
  const tenant:any = useAppSelector((state) => state.tenant);

  const { branding } = tenant;

  const [currentLogo, setCurrentLogo] = useState(null);

  useEffect(() => {
    if (branding.tenantUniqueId !== null)
      setCurrentLogo(getBrandingLogoByTenant(branding.tenantUniqueId));
  }, [branding.tenantUniqueId]);

  useEffect(() => {
    // Clear out messages
    message.destroy();
    notification.destroy();
  }, [])

  return (
    <Container className={styles["Landing-Container"]}>
      <div className={styles["Main-Header"]}>
        <Row>
          <Col>
            <img className={styles["Logo"]} src={currentLogo} onError={() => {
                setCurrentLogo(defaultLogo)
            }} />
          </Col>
        </Row>
      </div>
      <div className={styles.Content}>
        <div className={styles["Content-Header"]}>
          <Row>
            <Col>
              <h2>{tenant?.branding?.page?.appTitle || 'Digital Patient Journey'}</h2>
            </Col>
          </Row>
        </div>
        <div className={styles["Main-Content"]}>
                <>
                  {tenant.authenticationType?.includes(
                    AUTHENTICATION_TYPES.MOBILE
                  ) ? (
                    <MobileAuthenticationComponent />
                  ) : null}
                  {tenant.authenticationType?.includes(
                    AUTHENTICATION_TYPES.FIVE_FACES_SSO
                  ) ? (
                    <Row>
                      <Col>
                        <Button type="primary" onClick={login}>
                          Sign In with Five Faces SSO
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </>
        </div>
      </div>
      <LoginMessage />
    </Container>
  );
};

export default LoginModule;
