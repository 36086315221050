export const validateRequiredNode = (value, setProp) => {
    if (!value) {
        setProp(props => {
            props.error.required.isVisible = true;
            props.value = value;
        });
    } else {
        setProp(props => {
            props.error.required.isVisible = false;
            props.value = value;
        });
    }
};