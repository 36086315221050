import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { FieldComponent } from 'interfaces';
import { getFormFieldsDefaultValues } from 'helpers/form';
import {
    InputField,
    SelectField,
    DatePickerField,
    NumberField,
    StrictNumberField,
    AlphaNumericField,
    CleaveInputField
} from 'components/Controls';
import {
    Select
} from 'antd';
import { Button, Checkbox, Modal } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import {getPublicSetting} from "api/public/settings";
import moment from 'moment';

const { Option } = Select;
declare module "yup" {
    interface DateSchema {
        format(format: string): DateSchema;
    }
}

const invalidDate = new Date("");
Yup.addMethod<Yup.DateSchema>(Yup.date, "format", function (format: string) {
    return this.transform((value: Yup.DateSchema, input: string) => {
        const parsed = moment(input, format, true);
        return parsed.isValid() ? parsed.toDate() : invalidDate;
    });
});

interface FormProps {
    fields: FieldComponent[];
    submitButtonText?: string;
    isSubmitButtonLoading?: boolean,
    hasTermsAndConditions?: boolean,
    onSubmit?: (values?:any) => void;
}

export default (props: FormProps) => {
    const {
        fields,
        submitButtonText,
        onSubmit,
        isSubmitButtonLoading,
        hasTermsAndConditions
    } = props;

    const [isTermsAndConditionsChecked, setTermsAndConditions] = useState(false);
    const [isEnableTermsAndCondition, setEnableTermsAndCondition] = useState(false);

    const history = useHistory();

    useEffect(() => {
          const requestGetSettings = async () => {
              try {
                  const res = await getPublicSetting({
                      category: 'TENANT',
                      dataType: 'BOOLEAN',
                      name: 'TENANT_TERMS_REGISTRATION_ENABLED'
                  });
                  setEnableTermsAndCondition(JSON.parse(res.data.value.toLowerCase()));
              } catch (error) {
                  console.log(error);
              }
          }
          requestGetSettings();
      }, []);

    const buildValidationSchema = (fields: FieldComponent[]):any => {
        let schema:any = {};

        if (fields && fields.length > 0) {
            fields.map(field => {
                if (field.hasOwnProperty('validation')) {
                    if (field.validation && field.validation.length > 0) {
                        const YupObjectString = Yup.string();
                        field.validation.map(fieldValidation => {
                            if (fieldValidation.type === 'required') {
                                if (field.name === 'medicareNo') {
                                    schema[field.id] = Yup.string().ensure().when('irn', {
                                        is: (irn) => {
                                            return   irn ? true :false
                                        },
                                        then: Yup.string().required('Please enter your Medicare number') 
                                    });
                                    schema['irn'] = Yup.string().ensure().when('medicareNo', {
                                        is: (medicareNo) => {
                                            return   medicareNo ? true : false
                                        },
                                        then: Yup.string().required('Please enter your IRN number')
                                    });
                                }
                                else if (field.name === 'dva') {
                                    schema[field.id] = Yup.string().ensure().when(['medicareNo','irn'], {
                                        is: (medicareNo,irn) => {
                                            return  (medicareNo || irn) ? false : true
                                        },
                                        then: Yup.string().required('Please enter either your Medicare and IRN, or enter your DVA number') 
                                    });
                                }
                                else if (field.name === 'dob') {
                                    schema[field.id] = Yup
                                        .date()
                                        .format('DD/MM/YYYY')
                                        .typeError("Please enter a valid date of birth.")
                                        .required('Please enter a valid date of birth.') 
                                }
                                else {
                                    schema[field.id] = YupObjectString.required(fieldValidation.message);
                                }
                            }
                        })
                    }
                }
            })
        }

        return schema;
    }

    const getFieldComponent = (componentType: string) => {
        switch(true) {
            case componentType === 'text': 
                return InputField
            case componentType === 'dropdown':
                return SelectField
            case componentType === 'date':
                return CleaveInputField
            case componentType === 'medicareNumber':
                return NumberField
            case componentType === 'dvaNumber':
                return AlphaNumericField
            case componentType === 'number':
                return StrictNumberField
            default:
                return null
        }
    }

    return  <>
                <Formik
                    initialValues={getFormFieldsDefaultValues(fields)}
                    onSubmit={values => {
                        if (onSubmit) {
                            onSubmit(values);
                        }
                    }}
                    validationSchema={Yup.object().shape(buildValidationSchema(fields),[['medicareNo', 'irn']])}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <Row>
                                {fields 
                                    && fields.length > 0 
                                        && fields.map(field => {
                                            const {
                                                id,
                                                name,
                                                label,
                                                placeholder,
                                                size,
                                                type,
                                                items
                                            } = field;

                                            if (type !== 'medicareNumber' && type !== 'dvaNumber') {
                                                const fieldComponent: any = getFieldComponent(type);

                                                let FieldComponentElement: any = <Field
                                                        id={id}
                                                        name={name}
                                                        placeholder={placeholder}
                                                        size={size}
                                                        label={label}
                                                        component={fieldComponent}
                                                    />;

                                                if (type === 'date') {
                                                    FieldComponentElement = <Field
                                                                                id={id}
                                                                                name={name}
                                                                                placeholder={placeholder}
                                                                                size={size}
                                                                                label={label}
                                                                                component={fieldComponent}
                                                                                options={{
                                                                                    date: true,
                                                                                    delimiter: '/',
                                                                                    datePattern: ['d', 'm', 'Y'],
                                                                                    numericOnly: true
                                                                                }}
                                                                            />
                                                }

                                                if (type === 'dropdown') {
                                                    FieldComponentElement = <Field
                                                                                id={id}
                                                                                name={name}
                                                                                placeholder={placeholder}
                                                                                size={size}
                                                                                label={label}
                                                                                component={fieldComponent}
                                                                            >
                                                                                {items
                                                                                    && items.length > 0 
                                                                                    && items.map(item => {
                                                                                        const { value, displayText } = item;
                                                                                        return <Option value={value}>{displayText}</Option>
                                                                                })}
                                                                            </Field>
                                                }

                                                return (
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        {FieldComponentElement}
                                                    </Col>
                                                );
                                            }
                                })}
                                <div className="Medicare-Dva-box" style={((errors['irn'] && touched['irn']) || (errors['medicareNo'] && touched['medicareNo']) || (errors['dva'] && touched['dva'])) && {borderColor:'red'}}>
                                    {fields 
                                        && fields.length > 0 
                                            && fields.map(field => {
                                                const {
                                                    id,
                                                    name,
                                                    label,
                                                    placeholder,
                                                    size,
                                                    type,
                                                    items,
                                                } = field;

                                                if (type === 'medicareNumber' || type === 'dvaNumber') {
                                                    const fieldComponent: any = getFieldComponent(type);

                                                    let FieldComponentElement: any = <Field
                                                            id={id}
                                                            name={name}
                                                            placeholder={placeholder}
                                                            size={size}
                                                            label={label}
                                                            component={fieldComponent}
                                                        />;

                                                    if (type === 'medicareNumber') {
                                                        FieldComponentElement = <Row><Col
                                                            xs={8}
                                                            sm={8}
                                                            md={8}
                                                            lg={8}
                                                            xl={8}
                                                            className="pr-0"
                                                        >
                                                            <Field
                                                                id={id}
                                                                name={name}
                                                                placeholder={placeholder}
                                                                size={size}
                                                                label={label}
                                                                component={fieldComponent}
                                                                maxLength={10}
                                                            />
                                                        </Col>
                                                            <Col
                                                                xs={4}
                                                                sm={4}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                            >
                                                                <Field
                                                                    id="irn"
                                                                    name="irn"
                                                                    placeholder="IRN"
                                                                    size="large"
                                                                    label="IRN"
                                                                    component={fieldComponent}
                                                                    maxLength={1}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }

                                                    if (type === 'dvaNumber') {
                                                        FieldComponentElement = <Field
                                                                                    id={id}
                                                                                    name={name}
                                                                                    placeholder={placeholder}
                                                                                    size={size}
                                                                                    label={label}
                                                                                    component={fieldComponent}
                                                                                    maxLength={9}
                                                                                />
                                                    }

                                                    return (
                                                        <>
                                                            {type === 'dvaNumber' && 
                                                                <Col
                                                                    xs={1}
                                                                    sm={1}
                                                                    md={1}
                                                                    lg={1}
                                                                    xl={1}
                                                                    className="font-weight-bold pt-5 Or-Text"
                                                                    style={{ color: 'gray' }}
                                                                >
                                                                    <span>OR</span>
                                                                </Col>
                                                            }
                                                            {type === 'dvaNumber' ?
                                                                <Col
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={5}
                                                                    lg={5}
                                                                    xl={5}
                                                                >
                                                                    {FieldComponentElement}
                                                                </Col> : <Col
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                >
                                                                    {FieldComponentElement}
                                                                </Col>
                                                            }
                                                        </>
                                                    );   
                                                }
                                    })}
                                </div>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <p className='text-danger Error-Text'><ErrorMessage name='irn'/></p>
                                    <p className='text-danger Error-Text'><ErrorMessage name='medicareNo'/></p>
                                    <p className='text-danger Error-Text'><ErrorMessage name='dva'/></p>
                                </Col>
                                {hasTermsAndConditions && isEnableTermsAndCondition && <Col xs={12} className='text-center'>
                                    <Checkbox onChange={e => {
                                        const isCheckboxChecked = e.target.checked;

                                        if (isCheckboxChecked) {
                                            setTermsAndConditions(true);
                                        } else {
                                            setTermsAndConditions(false);
                                        }
                                    }}>I agree to the <Button className='TNC-Link-Button' type="link" onClick={() => {
                                        window.open('/collection-notice');
                                    }}>terms and conditions</Button></Checkbox>
                                </Col>}
                                <Col
                                    xs={12}
                                >
                                    <Button
                                        htmlType='submit'
                                        type='primary'
                                        className='mt-4'
                                        loading={isSubmitButtonLoading}
                                        disabled={hasTermsAndConditions && isEnableTermsAndCondition && !isTermsAndConditionsChecked}
                                        block
                                    >
                                        {submitButtonText ? submitButtonText : 'Submit'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
}