export enum MODULES {
    Onboarding = "Onboarding",
    Appointments = "Appointments",
    Inbox = "Inbox",
    Resources = "Resources",
    Forms = "Forms",
    Tasks = "Tasks",
    Chatbot = "Chatbot",
    Settings = "Settings",
    Heartrate = "Heartrate"
}
