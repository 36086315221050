export const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export const handleResponsive = ({
    xsCallback,
    smCallback,
    mdCallback,
    lgCallback,
    xlCallback,
}) => {
    const handleScreenSizes = () => {
        const currentWindowWidth = window.innerWidth;

        if (currentWindowWidth < 576) {
            if (xsCallback && isFunction(xsCallback)) {
                xsCallback();
            }
        }

        if (currentWindowWidth >= 576) {
            if (smCallback && isFunction(smCallback)) {
                smCallback();
            }
        }

        if (currentWindowWidth >= 768) {
            if (mdCallback && isFunction(mdCallback)) {
                mdCallback();
            }
        }

        if (currentWindowWidth >= 992) {
            if (lgCallback && isFunction(lgCallback)) {
                lgCallback();
            }
        }

        if (currentWindowWidth >= 1200) {
            if (xlCallback && isFunction(xlCallback)) {
                xlCallback();
            }
        } 
    };

    (function () {
        handleScreenSizes();
    })();
    window.addEventListener('resize', handleScreenSizes);
};

export const hexToRgbA = (hex, opacity) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', ' + opacity + ')';
    }
    throw new Error('Bad Hex');
}

export const createGoogleFontLinkTag = (
    source,
    id
) => {
    if (!document.getElementById(id)) {
        const link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.href = source;
        document.head.appendChild(link);
    } else {
        const link = document.getElementById(id);
        link.href = source;
    }
}

const collectionHas = (a, b) => {
    for(var i = 0, len = a.length; i < len; i++) {
        if(a[i] == b) return true;
    }
    return false;
}

export const findParentElementBySelector = (elm, selector) => {
    var all = document.querySelectorAll(selector);
    var cur = elm.parentNode;
    while(cur && !collectionHas(all, cur)) {
        cur = cur.parentNode;
    }
    return cur;
}

export const preventNonNumericalInput = (e) => {
    e = e || window.event;
    var charCode = (typeof e.which === "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (charCode === 13)
        return true;

    if (!charStr.match(/^[0-9]+$/))
        e.preventDefault();
}

var getKeyCode = function (str) {
    return str.charCodeAt(str.length - 1);
}

export const preventNonAlphaNumericalInput = (e) => {
    e = e || window.event;
    var charCode = (typeof e.which === "undefined") ? e.key : e.which;
    
    if(!charCode || charCode === 229) {
        charCode = getKeyCode(e.target.value);
    }
    
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[a-zA-Z0-9]+$/)) {
        e.preventDefault();
    }
        
}

export const AllowNumberForMobile = (e) => {
    e.target.value = e.target.value.replace(/[^\d]/g,'');
    return false;
}
