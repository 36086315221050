import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Button} from "antd";
import { message } from 'helpers/notificationhelper';
import {Field, Form, Formik} from 'formik';

import 'styles/TwoFactorAuth.scss';
import InputFieldPIN from "components/Controls/InputFieldPIN";

import * as Yup from 'yup';
import {confirmPINPatientUser, regeneratePINPatientUser} from "api/users";
import InputField from "components/Controls/InputField";
import {Link} from "react-router-dom";

export default ({onSuccess, uniqueId}) => {
    const handleResendCode = e => {
        regeneratePINPatientUser(uniqueId)
        .then(response => {
            console.log(response);
            if(response && response.status === 200) {
                message.success('Code successfully sent');
            }
        }).catch(err => {
            console.err(err);
            message.error('Something went wrong');
        }).finally(() => {

        })
    }

    return (
        <Formik
            initialValues={{
                pin: null
            }}
            validationSchema={Yup.object({
                pin: Yup.string().nullable().required("Enter PIN sent to your mobile no. via SMS")
            })}
            onSubmit={val => {
                confirmPINPatientUser(val, uniqueId)
                .then(response => {
                    console.log(response);
                    if(onSuccess) onSuccess(response);
                }).catch((err) => {
                    const response = err.response;
                    if(response && response.data && response.status === 409){
                        message.error(response.data.message);
                    } else {
                        console.log(response);
                        message.error('Something went wrong');
                    }
                });
            }}>
            <Form>
                <Row>
                    <Col>
                        <h3>Verify sign up request</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>We have sent a code to your mobile phone via SMS. Please enter it below to continue.</p>
                    </Col>
                </Row>
                <Row className='Code-Key-Container' style={{textAlign: 'center'}}>
                    <Col xs={12}>
                        <Field
                            name='pin'
                            placeholder='PIN'
                            component={InputField}/>
                    </Col>
                    <Col xs={12} style={{margin:'1rem 0 2rem 0', textAlign: 'left'}}>
                        <span >Haven't received code?&nbsp;<Link to='#' onClick={handleResendCode}>Resend Code</Link></span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            htmlType='submit'
                            type='primary'
                            className='mt-2'
                            block>Next</Button>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
}