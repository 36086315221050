import { API_BASE_URL } from './config';
import { createAxios } from './axios';

const patientsApi = createAxios({
    baseURL: `${API_BASE_URL}/patients`
});

export const deletePatient = (patientId: string) => {
    return patientsApi.delete(`/${patientId}`);
}

export const changeLocationStatus = (status: Boolean) => {
    return patientsApi.get('/update-location?locationEnable='+status);
}
