import { Card, Image } from 'antd';
import React from 'react';
import { BiMap } from 'react-icons/all';
import styled from 'styled-components';

const MapContainer = styled.div`
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #404040;

    .antd-image {
        background: #404040;
        width: 100%!important;
        height: auto;

        .ant-image-img {
            width: 100%;
        }

        &:hover {
            cursor: pointer;
        }
    }
`;

const NoMapAvailableComponent = styled.div`
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ddd;
    color: #9e9e9e;
`;

function MapImage({
    clinicMapUrl
}) {
    return (
        <Card className='Card-Grids' style={{border:'none', borderRadius: '10px'}}>
            {clinicMapUrl ? 
            <MapContainer>
                <Image
                    src={clinicMapUrl}
                    style={{
                        maxHeight: '55vh'
                    }}
                /> 
            </MapContainer>
            : 
            <NoMapAvailableComponent>
                <BiMap fontSize={60} />
                <strong>No Map Available</strong>
            </NoMapAvailableComponent>}
        </Card>
    );
}

export default MapImage;