import { API_BASE_URL } from 'api/config';
import {createAxios} from "./axios";

const notificationsApi = createAxios({
    baseURL: API_BASE_URL + '/notifications/'
});

export const getNotificationsByPatientId = (id, additionalParams = '') => {
    return notificationsApi.get(`?patientId=${id}${additionalParams}`);
};

export const getNotificationById = id => {
    return notificationsApi.get(`/${id}`);
};

export const getUnreadNotificationsCount = id => {
    return notificationsApi.get(`/unread/count?patientId=${id}`);
};

export const markNotificationAsRead = () => {
    return notificationsApi.patch(`read-and-clear`);
};

export const markNotificationsAsClear = () => {
    return notificationsApi.patch('clear-status');
};

export const setNotificationAsReadById = notificationId => {
    return notificationsApi.post(`/read-status`, notificationId);
};

export const sendNotificationsAck = () => {
    return notificationsApi.get('ack');
};