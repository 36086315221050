import {AUTHENTICATION_TYPES, TENANT_ACTIONS, TenantRegistration} from "./tenant-types";

export enum AUTH_ACTIONS {
    SET_TOKEN = "SET_TOKEN",
    REMOVE_TOKEN = "REMOVE_TOKEN"
}

export interface AuthAction {
    type: AUTH_ACTIONS,
    payload: Partial<AuthState>
}

export interface AuthState {
    token: string | undefined
}