import React, { FunctionComponent, useEffect, useState } from "react";
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  withStyles,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/Settings.scss";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./../../hooks/use-app-selector";
import styled from "styled-components";
import { AiOutlineDelete } from "react-icons/all";
import CloseIcon from "@material-ui/icons/Close";
import { keycloak } from "../../api/keycloak";
import Cookies from "js-cookie";
import { TOKEN_NAME } from "../../constants";
import { Modal, message , Switch, notification , Select, Spin } from 'antd';
import { message as notificationMessage } from 'helpers/notificationhelper';
import { deletePatient , changeLocationStatus } from "../../api/patients";

import {AUTH_ACTIONS} from 'redux/types/auth-types';
import { accountDeleteMessage } from 'api/settings';
import {
  setCurrentUserLocationPreferences,
  setCurrentUserLocationStatus
} from 'redux/actions/currentUser';

import {
  logout
} from 'api/logout';
import parser from 'html-react-parser';
import { setNotificationAsClear, setNotificationAsRead } from "redux/actions/notifications";

const { confirm } = Modal;
const { Option } = Select;

interface SettingsModuleProps {
  isOpen: boolean;
  handleClose: () => void;
}

const DeleteAccountButton = styled.span`
  color: rgba(239, 68, 68, 1);
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: rgba(185, 28, 28, 1);
  }
`;

const SettingsModule: FunctionComponent<SettingsModuleProps> = ({
  isOpen,
  handleClose,
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down(576)); // defined in styles/_responsive.scss
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useAppSelector((state) => state.permissions.permissions!);
  const currentUser = useAppSelector((state: any) => state?.currentUser?.data);
  const [deletePopupMesssage, setDeletePopupMessage] = useState(null);
  const [locationEnable, setLocationEnable] = useState('2');
  const [onDropDownChangeLoading,setDropDownChangeLoading] = useState(false);

  useEffect(() => {
    let locationEnabled = currentUser?.alwaysAsk;
    setLocationEnable(locationEnabled ? 2 : currentUser?.locationEnabled);
  },[currentUser])

  useEffect(() => {
    const requestGetSettings = async () => {
      try {
        const res = await accountDeleteMessage();
        const val = res && res.data ? res.data.value : "";
        setDeletePopupMessage(val);
      } catch (error) {
        console.log(error);
      }
    };

    requestGetSettings();
  }, []);
  const showConfirmDeleteAccountModal = (patientId) => {
    document.body.removeAttribute('style');
    confirm({
      title: "Confirm",
      content:  deletePopupMesssage && parser(deletePopupMesssage),
      okText: "Yes",
      cancelText: "No",
      maskClosable: true,
      onOk: async () => {
        try {
          message.loading(t("message.deleting_account"), 0);
          await deletePatient(patientId);

          message.destroy();
          notification.destroy();
          notificationMessage.success(t('message.delete_account_success'));
          logout({})
            .then(e => {
              if (e && e.status === 200) {
                dispatch({
                  type: AUTH_ACTIONS.REMOVE_TOKEN
                });
                Cookies.remove(TOKEN_NAME);
                void window.location.assign('/');
              }
            })
            .catch(e => {
              if (e && e.status === 500) {
                notificationMessage.error("An error has occurred. Please try again later.")
              }
            });

        } catch (e) {
          logout({})
            .then(e => {
              if (e && e.status === 200) {
                dispatch({
                  type: AUTH_ACTIONS.REMOVE_TOKEN
                });
                Cookies.remove(TOKEN_NAME);
                void window.location.assign('/');
              }
            })
            .catch(e => {
              if (e && e.status === 500) {
                notificationMessage.error("An error has occurred. Please try again later.")
              }
              else{
                message.destroy();
                notification.destroy();
              }
            });
        }
      },
    });
  };

  // for toggling a location for logged in patient
  const locationToggler = (value) => {
    setDropDownChangeLoading(true);
    changeLocationStatus(value).then((response) => {
     // setLocationEnable(!locationEnable);
      setDropDownChangeLoading(false);
      dispatch(setCurrentUserLocationStatus(value));
      dispatch(setCurrentUserLocationPreferences(value === '2'));
    }).catch((err) => {
      setDropDownChangeLoading(false);
    })
  }

  return (
    <Dialog className="Settings-Dialog" open={isOpen} fullScreen={isFullScreen}>
      <Spin spinning={onDropDownChangeLoading}>
      <div className="Settings-Wrapper h-100">
        <DialogTitle
          className="Settings-Title text-center"
          id="responsive-dialog-title"
        >
          <Container>
            <Row className="mt-5">
              <Col>
                <h2>Profile Settings</h2>
              </Col>
            </Row>
            <IconButton
              className="Close-Button-Style"
              aria-label="close"
              style={{
                position: "absolute",
                right: 5,
                top: 5,
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Container>
        </DialogTitle>
        <DialogContent className="Settings-Content bg-white mt-5">
          <Container fluid>
            <Form>
              <Row>
                <Col lg={6} md={6} xs={12} sm={12}>
                  <Form.Group className="mb-1">
                    <Form.Label>Name</Form.Label>
                    <Tooltip title='Not editable' placement="top">
                      <Form.Control
                        type="text"
                        style={{ fontSize: "17px", borderRadius: "15px", backgroundColor: "#FFFAFA" }}
                        value={currentUser?.firstName + " " + currentUser?.lastName}
                        readOnly
                      />
                    </Tooltip>
                  </Form.Group>

                </Col>
                <Col lg={6} md={6} xs={12} sm={12}>
                  <Form.Group className="mb-1">
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      type="text"
                      style={{ fontSize: "17px", borderRadius: "15px", backgroundColor: "#FFFAFA" }}
                      value={currentUser?.patient?.sex ? currentUser?.patient?.sex : ''}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} xs={12} sm={12}>
                  <Form.Group className="mb-1">
                    <Form.Label>Medicare Number</Form.Label>
                    <Form.Control
                      type="text"
                      style={{ fontSize: "17px", borderRadius: "15px", backgroundColor: "#FFFAFA" }}
                      value={currentUser?.patient?.medicareCardNumber}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12} sm={12}>
                  <Form.Group className="mb-1">
                    <Form.Label>DVA Number</Form.Label>
                    <Form.Control
                      type="text"
                      style={{ fontSize: "17px", borderRadius: "15px", backgroundColor: "#FFFAFA" }}
                      value={currentUser?.patient?.dva}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} xs={12} sm={12}>
                  <Form.Group className="mb-1">
                    <Form.Label>Date Of Birth</Form.Label>
                    <Form.Control
                      type="text"
                      style={{ fontSize: "17px", borderRadius: "15px", backgroundColor: "#FFFAFA" }}
                      value={ moment.utc(currentUser?.dateOfBirth).local().format(
                        'DD MMM YYYY'
                      )}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12} sm={12}>
                  <Form.Group className="mb-1">
                    <Form.Label>Mobile No</Form.Label>
                    <Form.Control
                      type="text"
                      style={{ fontSize: "17px", borderRadius: "15px", backgroundColor: "#FFFAFA" }}
                      value={currentUser?.mobileNumber}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col lg={6} md={6} xs={12} sm={12}>
                  <Form.Group className="mb-1">
                    <Form.Label style={{marginRight:'10px'}}>Enable Location Services:</Form.Label>
                    {/* <Switch defaultChecked={locationEnable} onChange={locationToggler}/> */}
                    <select className="mobile-select" style={{width:'100%'}} defaultValue={locationEnable} onChange={(e) => locationToggler(e?.currentTarget?.value)}>
                      <option value={2}>Ask Everytime</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row className='mt-2'>
                <strong style={{ color: '#4985E2' }}>
                    Ensure to enable/allow system location settings when prompted
                </strong>
            </Row>
            <Row className={"mt-5"}>
              {permissions.Settings?.includes("settings:delete-account") ? (
                <Col>
                  <DeleteAccountButton
                    onClick={() => {
                      handleClose();
                      showConfirmDeleteAccountModal(currentUser.id);
                    }}
                  >
                    <AiOutlineDelete
                      style={{
                        marginRight: "5px",
                      }}
                    />
                    {t("label.delete_account")}
                  </DeleteAccountButton>
                </Col>
              ) : null}
            </Row>
          </Container>
        </DialogContent>
      </div>
      </Spin>
    </Dialog>
  );
};

export default SettingsModule;
