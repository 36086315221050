import React, { FunctionComponent, useState } from "react";
import { useEffect } from "react";
import {getPublicSetting} from "../api/public/settings";
import Helmet from "react-helmet";
import parser from 'html-react-parser';

interface CollectionNoticeProps {
  title: string;
}

const CollectionNotice: FunctionComponent<CollectionNoticeProps> = (props) => {
    const [privacyPolicy, setPrivacyPolicy] = useState(null);

    useEffect(() => {
        const requestGetSettings = async () => {
            try {
                const res = await getPublicSetting({
                    category: 'TENANT',
                    dataType: 'HTML',
                    name: 'TENANT_TERMS_REGISTRATION'
                });
                setPrivacyPolicy(res.data.value);
            } catch (error) {
                console.log(error);
            }
        }

        requestGetSettings();
    }, []);

  return (
    <>
      <Helmet>
        <style type="text/css">{`
        html, body {
            height: 100%;
            background-color: white !important;
        }
    `}</style>
      </Helmet>
      <div
        className="container"
        style={{
          height: "100vh",
          backgroundColor: "#FFF",
        }}
      >
        {privacyPolicy && parser(privacyPolicy)}
      </div>
    </>
  );
};

export default CollectionNotice;
