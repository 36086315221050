import { MODULES } from "./modules"

import { APPOINTMENTS_PERMISSIONS } from "./appointments-permissions"
import { INBOX_PERMISSIONS } from "./inbox-permissions"
import { RESOURCES_PERMISSIONS } from './resources-permissions';
import { FORMS_PERMISSIONS } from "./forms-permissions"
import { TASKS_PERMISSIONS } from './tasks-permissions';
import { CHATBOT_PERMISSIONS } from './chatbot-permissions';
import { SETTINGS_PERMISSIONS } from "./settings-permissions";
import { ONBOARDING_PERMISSIONS } from "./onboarding-permissions";
import { HEARTRATE_PERMISSIONS } from './heartrate-permissions';

export enum PERMISSIONS_ACTIONS {
    SET_PERMISSIONS = "SET_PERMISSIONS"
}

export interface PermissionsAction {
    type: PERMISSIONS_ACTIONS,
    payload: PermissionsState
}

type Permissions = {
    [MODULES.Heartrate]: Array<keyof typeof HEARTRATE_PERMISSIONS>;
    [MODULES.Onboarding]: Array<keyof typeof ONBOARDING_PERMISSIONS>;
    [MODULES.Appointments]: Array<keyof typeof APPOINTMENTS_PERMISSIONS>;
    [MODULES.Inbox]: Array<keyof typeof INBOX_PERMISSIONS>;
    [MODULES.Resources]: Array<keyof typeof RESOURCES_PERMISSIONS>;
    [MODULES.Forms]: Array<keyof typeof FORMS_PERMISSIONS>;
    [MODULES.Tasks]: Array<keyof typeof TASKS_PERMISSIONS>;
    [MODULES.Chatbot]: Array<keyof typeof CHATBOT_PERMISSIONS>;
    [MODULES.Settings]: Array<keyof typeof SETTINGS_PERMISSIONS>;
}

export interface PermissionsState {
    permissions: Partial<Permissions>
}
