import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledTag = styled(Tag)`
    border-radius: 50px;
`;

const AppointmentTypeTag = (props) => {
    const tagColor = (type) => {
        const appointmentType = type?.toLowerCase();
        switch (appointmentType) {
            case 'face to face':
                return '#2db7f5'
            case 'telehealth':
                return '#389e0d'
            case 'telephonic':
                return '#b37feb'
        }
    }

    return props?.type ? <StyledTag color={tagColor(props.type)} {...props}>{props.type}</StyledTag> : null
}

export default AppointmentTypeTag;
