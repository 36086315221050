import { SET_CURRENT_USER, UNSET_CURRENT_USER , SET_CURRENT_USER_STATUS, SHOW_ACCOUNT_SETTINGS, SET_CURRENT_USER_PREFERENCE } from 'redux/actions/types/currentUser';

const INITIAL_STATE = {
    data: undefined,
    showAccountSettings: false
};

const currentUserReducer = (state = INITIAL_STATE, action) => {
    const { type, payload, error } = action;

    switch (type) {

    case SET_CURRENT_USER: {
        return {
            data: payload
        }
    }

    case UNSET_CURRENT_USER: {
        return INITIAL_STATE
    }

    case SET_CURRENT_USER_STATUS: {
        let patient = {
            ...state.data.patient,
            locationEnabled: parseInt(payload)
        }
        let locationEnabled = parseInt(payload);
        return {
            data: {...state.data, patient, locationEnabled}
        }
    }


    case SET_CURRENT_USER_PREFERENCE: {
        let patient = {
            ...state.data,
            alwaysAsk: payload
        }
        let alwaysAsk = payload;
        return {
            data: {...state.data, alwaysAsk, patient}
        }
    }

    case SHOW_ACCOUNT_SETTINGS: {
        return {
            data:{...state.data},
            showAccountSettings: payload
        }
    }

    default:
        return state
    }
};

export default currentUserReducer;
