// components/user/Button.js
import React from 'react';
import { Button as AntButton, Select } from 'antd';
import { message } from 'helpers/notificationhelper';

import { useEditor } from '@craftjs/core';

import moment from 'moment';

const nonValuedControls = ['Container', 'Card', 'Panel', 'Column', 'Button', 'Text'];

const Button = ({ 
    title = 'Button',
    type = 'primary'
}) => {
    const {
        actions: { setProp },
        query,
        nodes,
    } = useEditor((state, query) => ({
        nodes: state.nodes
    }));

    return (
        <AntButton
            size='small' 
            type={type}
            onClick={() => {
                let data = [];
                let errors = [];
                
                Object.keys(nodes).map(serializeNodeKey => {
                    const nodeControl = query.getSerializedNodes()[serializeNodeKey];
                    
                    if (!nonValuedControls.includes(nodeControl.displayName)) {
                        if (nodeControl.props.isRequired && !nodeControl.props.value) {
                            errors.push(serializeNodeKey);

                            setProp(serializeNodeKey, props => {

                                if (props.error) {
                                    if (nodeControl.props.name === 'input' && nodeControl.props.type === 'datetime') props.value = moment();
                                    if (nodeControl.props.name === 'select') props.value = props.options && props.options.length > 0 && props.options[0].value;
                                    if (nodeControl.props.name === 'input' && nodeControl.props.type === 'text') props.value = '';
                                       
                                    props.error.required.isVisible = true;
                                }
                            });
                        } else {
                            if (nodeControl.props.value) {
                                setProp(serializeNodeKey, props => {
                                    if (props.error) props.error.required.isVisible = false;
                                });

                                delete errors[serializeNodeKey];
                                
                                data.push({
                                    id: serializeNodeKey,
                                    name: nodeControl.props.name, 
                                    value: nodeControl.props.value
                                });
                            }
                        }
                    }
                });
                
                if (errors && errors.length < 1) {
                    message.success('Form successfully submitted');
                    console.log(data)
                } else {
                    message.error('Please fill in the required field(s).');
                }
            }}
        >
            {title}
        </AntButton>
    );
};

export default Button;
