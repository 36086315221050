import React, {useEffect, useMemo, useState} from 'react';
import moment from "moment";
import {Apartment,} from '@material-ui/icons';
import {CompleteButton} from "../CompleteButton";

import {Alert, Badge, Button, Card, Checkbox, Modal, notification, Spin} from "antd";
import {message} from 'helpers/notificationhelper';
import {CheckCircleFilled, EditOutlined} from '@ant-design/icons';
import {Col, Container, Row} from 'react-bootstrap';

import {useTranslation} from 'react-i18next';
import {useAppSelector} from 'hooks/use-app-selector';
// import UpcomingAppointmentExtraOptions from "./components/UpcomingAppointmentExtraOptions";
import {useDispatch} from "react-redux";
import AppointmentTypeTag from 'components/Appointments/AppointmentTypeTag'
import {confirmAppointmentStatus} from "redux/actions/appointment";
import {TENANT_ACTIONS} from "redux/types/tenant-types";

import styled from "styled-components";

import {getSetting} from 'api/settings';

import {checkInAppointment} from "../../api/appointments";
import {setCurrentUserLocationStatus, setCurrentUserLocationPreferences} from 'redux/actions/currentUser';

import {getPublicSetting} from "api/public/settings";
import Interweave from 'interweave';

export const CheckInButton = styled(Button)`
  min-height: unset;
  & span {
    font-size: 12px;
  }
`

const AppointmentCard = ({ ActionLayout, item, span, pageNumber }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmStatusClick, setConfirmStatusClick] = useState(false);
  const [confirmSuccessStatus, setConfirmSuccessStatus] = useState(false);
  const [checkInInfo, setCheckInInfo] = useState({
    type: '',
    data: ''
  });
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const { confirm, warning, success } = Modal;

  const currentUser = useAppSelector(state => state.currentUser.data);
  const tenantSettings = useAppSelector(state => state.tenant.settings);

  const [badgeRibbenData,setBadgeRibbenData] = useState({});
  const [checkInRememberModal,showCheckInRememberModal] = useState(false);
  const [rememberLocationChoice,setRememberLocationChoice] = useState(false); // ALWAYS ASK BY DEFAULT

  const changeCheckInPreference = () => {
    setRememberLocationChoice(!rememberLocationChoice);
  }

  async function getAndSetTenantAppointmentTileConfirmAskMessage() {
    const res = await getPublicSetting({
        category: 'TENANT',
        dataType: 'HTML',
        name: 'APPOINTMENT_TILE_CONFIRM_ASK_MESSAGE'
    })


    const updatedTenantSettings = {
        ...tenantSettings,
        appointmentTileConfirmAskMessage: res.data.value
    }

    dispatch({
        type: TENANT_ACTIONS.SET_TENANT_SETTINGS,
        payload: updatedTenantSettings
    })

    const resNetwork = await getPublicSetting({
      category: 'TENANT',
      dataType: 'HTML',
      name: 'NETWORK_ERROR_MESSAGE',
      defaultValue: 'Network Error!'
  })
    if (resNetwork && resNetwork.data && resNetwork.data.value ){
      sessionStorage.setItem('NETWORK_ERROR_MESSAGE', resNetwork.data.value)
    }

  }

  useEffect(() => {
    getAndSetTenantAppointmentTileConfirmAskMessage()
  }, []);

  useMemo(() => {
    if (span !== "PAST" && confirmStatusClick) {
      confirm({
        title: 'Confirm',
        content: tenantSettings.appointmentTileConfirmAskMessage ? <Interweave content={tenantSettings.appointmentTileConfirmAskMessage} /> : '',
        okText: 'Yes',
        cancelText: 'No',
        maskClosable: true,
        onCancel: () => {
          setConfirmStatusClick(false);
        },
        onOk: () => {
          try {
            (async () => {
              const response = await dispatch(confirmAppointmentStatus(item.id, pageNumber));
              if (response !== 'error')
              message.success('Appointment successfully confirmed.');
              setConfirmStatusClick(false);
            })();
          } catch(e) {
            message.error(e);
            setConfirmStatusClick(false);
          }
        }
      });
    }
  }, [confirmStatusClick, span]);

  useMemo(() => {
    let labelstatus = item?.labelStatus?.toUpperCase();
    if (labelstatus === 'CONFIRMED' && span !== "PAST" && confirmSuccessStatus)
      message.success('Appointment successfully confirmed.');
  }, [confirmSuccessStatus, item, item?.labelstatus, span]);

  useEffect(() => {
    let labelstatus = item?.labelStatus?.toUpperCase();
    switch (labelstatus) {
      case 'COMPLETE':
      case 'COMPLETED':
        labelstatus = 'COMPLETED';
        setBadgeRibbenData({color:'royalblue',text:labelstatus});
        break;
      case 'CONFIRMED':
        setBadgeRibbenData({color:'green',text:labelstatus});
        break;
      case 'UNCONFIRMED':
        setBadgeRibbenData({color:'red',text:labelstatus});
        break;
      case 'CANCELLED':
        setBadgeRibbenData({color:'red',text:labelstatus});
        break;
      case 'DID NOT ATTEND':
        setBadgeRibbenData({color:'volcano',text:labelstatus});
        break;
      case 'SCHEDULED':
        setBadgeRibbenData({color:'gold',text:labelstatus});
        break;
      case 'IN PROGRESS':
        setBadgeRibbenData({color:'purple',text:labelstatus});
        break;
      default:
        break;
    }
  },[item, item?.labelstatus])

  const getCheckInAppointmentInfo = (payload='') => {
    setIsCheckingIn(true);
    checkInAppointment(payload)
      .then((res) => {
        setIsCheckingIn(false);
        setCheckInInfo(res.data);
        setShowCheckInModal(true);
      })
      .catch((error) => {
        setIsCheckingIn(false);
        message.error(error);
      });
  }

  const locationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        warning({
          title: '',
          content: 'You need to enable location permissions on your device in order to use Check In with location services.',
          maskClosable: true
        });
        break;
      case error.POSITION_UNAVAILABLE:
        message.error("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        message.error("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        message.error("An unknown error occurred.");
        break;
    }
  }

  const setPositionValues = (position) => {
    const payload = {
      latitude: position.coords.latitude?.toString(),
      longitude: position.coords.longitude?.toString(),
      locationServiceEnabled: 1,
      alwaysAsk: false,
      appointmentId: item.id,
    };
    getCheckInAppointmentInfo(payload);
  }

  const getPosition = (position) => {
    const payload = {
      latitude: position.coords.latitude?.toString(),
      longitude: position.coords.longitude?.toString(),
      locationServiceEnabled: 1,
      alwaysAsk: !rememberLocationChoice,
      appointmentId: item.id,
    };
    getCheckInAppointmentInfo(payload);
  }

  const checkInConfirm = () => {
    navigator.geolocation.getCurrentPosition(getPosition, locationError);
    showCheckInRememberModal(false);
    dispatch(setCurrentUserLocationPreferences(!rememberLocationChoice));
    dispatch(setCurrentUserLocationStatus(1));
  };

  const onCheckInClick = () => {
    if (currentUser?.alwaysAsk || currentUser?.locationEnabled === 2) { // YES ALWAYS ASK
      showCheckInRememberModal(true);
    } else if (currentUser?.locationEnabled === 1) { // if enabled

      navigator.geolocation.getCurrentPosition(setPositionValues, locationError);
      dispatch(setCurrentUserLocationPreferences(false));

    } else if (currentUser?.locationEnabled === 0) { // if disabled
      getCheckInAppointmentInfo({
        latitude: '',
        longitude: '',
        locationServiceEnabled: 0,
        alwaysAsk: false,
        appointmentId: item.id,
      });

      dispatch(setCurrentUserLocationPreferences(false));
    } else {
      console.log(currentUser);
    }
  }

  const onCheckInDenied = () => {
    getCheckInAppointmentInfo({
        latitude: '',
        longitude: '',
        locationServiceEnabled: 0,
        alwaysAsk: !rememberLocationChoice,
        appointmentId: item.id,
      });

      showCheckInRememberModal(false);
      dispatch(setCurrentUserLocationPreferences(!rememberLocationChoice));
      dispatch(setCurrentUserLocationStatus(0));
  }

  const CodeWrapper = () => {
    return <>
      <Card className="Appointment-Card" actions={ActionLayout}>
        <div className={`Appointment-Date-Container ${span === 'PAST' ? 'Past-Appointment-Date' : ''}`}>
          <p className="mb-0">{moment.utc(item.appointmentDateTime).local().format("MMM DD, YYYY h:mm A").toString()}</p>
        </div>
        <Container fluid>
          {/* <Row>
            <Col xs={8}>
                <h4 className="Appointment-Booking-Details">
                    <Today className='Appointment-Icon' fontSize="small" />{" "}
                    {moment(item.appointmentDateTime).format("DD MMMM YYYY").toString()}
                </h4>
            </Col>
            <Col xs={4} className='text-right'>
                <h4 className="Appointment-Booking-Details">
                    <Schedule className='Appointment-Icon' fontSize="small" />{" "}
                    {moment(item.appointmentDateTime).format("hh:mm A").toString()}
                </h4>
            </Col>
          </Row><hr/> */}
          <Row className='Card-Content-Top-Margin'>
            <Col
              xs={{
                span: 12,
              }}
              md={{
                span: 12,
              }}
              lg={{
                span: 12,
              }}
            >
              <Row className="Appointment-Card-Row">
                <Col xs={12} md={12} lg={12}>
                  {(item.locationName || item.location.name) && (
                    <h4 className="Appointment-Booking-Details Location font-weight-bold mt-2 mb-1 d-flex">
                      <Apartment className="Appointment-Icon" fontSize="small" />{" "}
                      {item.locationName ? item.locationName : item.location.name}
                    </h4>
                  )}
                  {(item.parentLocationName) && (
                    <h4 className="Appointment-Booking-Parent-Location">
                      {item.parentLocationName && item.parentLocationName}
                    </h4>
                  )}
                  {/* uncommented this code as per Ticket TDPJ-320 */}
                  {/* {span !== "PAST" && (
                    <h4 className="Appointment-Booking-Details Appointment-Booking-Status d-flex align-items-center">
                      {returnStatusTemplate(item.appointmentStatus ? item.appointmentStatus : item.status)}
                    </h4>
                  )} */}
                  {item?.attendanceType && <h4>{item.appointmentType}</h4>}
                  {/* {span !== "PAST" ? <UpcomingAppointmentExtraOptions /> : null} */}
                  {item?.attendanceType && <Badge
                    className="Appointment-Badge"
                    count={item.attendanceType}
                    size={"small"}
                  />}

                </Col>
                {span !== "PAST" && <Col xs={12} md={12} lg={12} className="Appointment-Booking-Buttons">
                  {(
                    <CheckInButton
                      style={{ margin: '2px' }}
                      type="default"
                      onClick={() => {onCheckInClick()}}
                      className="Check-In-Button"
                      disabled={!item.displayCheckInButton}
                    >
                      <CheckCircleFilled
                        style={{
                          position: "relative",
                          top: "-2px",
                          height: "10px",
                        }}
                      />
                      {t('label.check_in')}
                    </CheckInButton>
                  )}
                  {(
                    <CompleteButton
                      style={{ margin: '2px' }}
                      type="primary"
                      onClick={() => {
                        setConfirmStatusClick(true);
                      }}
                      className="Tile-Button-Align"
                      disabled={(item?.override || (!item?.override && item?.labelStatus?.toUpperCase() === "SCHEDULED")) ? false : true}
                    >
                      <CheckCircleFilled
                        style={{
                          position: "relative",
                          top: "-2px",
                          height: "10px",
                        }}
                      />
                      Confirm
                    </CompleteButton>
                  )}
                  {(
                    <CompleteButton
                      style={{ margin: '2px' }}
                      type="primary"
                      onClick={() => {
                        const changeAppoinment = async () => {
                          const params = {
                            category: 'FHIR',
                            dataType: 'FREE_TEXT',
                            name: 'CHANGE_BUTTON_CONFIG_MESSAGE',
                          };
                          const res = await getSetting(params);
                          if (res?.data?.value) {
                            notification['info']({description: res?.data?.value,duration:10});
                          }
                        }
                        changeAppoinment();
                      }}
                      className="Tile-Button-Align"
                      disabled={item && (item.labelStatus?.toUpperCase() === 'SCHEDULED' || item.labelStatus?.toUpperCase() === 'CONFIRMED') ? false : true}
                    >
                      <EditOutlined
                        style={{
                          position: "relative",
                          height: "12px",
                        }}
                      />
                      Change
                    </CompleteButton>
                  )}
                </Col>}
              </Row>
              {item.appointmentType && <Row>
                <Col xs={12} md={12} lg={12}>
                    <AppointmentTypeTag type={item.appointmentType} className='my-2 ml-2' />
                </Col>
              </Row>}
              <Row className="Appointment-Card-Row mt-4">
                <Col xs={12} md={12} lg={12}>
                  {item?.checkInInstruction && <h5 className="mt-2">{item?.checkInInstruction}</h5>}
                </Col>
                {span !== 'PAST' && item.displayBarcode && item.barcode &&
                  <Col xs={12} md={12} lg={12} className="text-right Qr-Code">
                    <img className='img-responsive' src={`data:image/png;base64,${item.barcode}`} />
                  </Col>
                }
              </Row>
              {/* {span !== "PAST" ? <UpcomingAppointmentExtraOptions /> : null} */}
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  }

  return (
    <>
        <Modal
          title="Confirm"
          visible={checkInRememberModal}
          // onOk={checkInConfirm}
          onCancel={() => {
            showCheckInRememberModal(false)
          }}
          footer={[
            <Button key="back" onClick={() => onCheckInDenied()}>
                No
            </Button>,
            <Button key="submit" type="primary" onClick={() => checkInConfirm()}>
                Yes
            </Button>,
          ]}
        >
          <h4 style={{fontWeight:'bold'}}>Please enable location services to allow easy check-in from the App</h4>
          <p/>
          <Alert message="This app uses your phone's location only while checking you in for the appointment. You can always turn it off from Account Settings." type="info" />
          <div class='mt-3'>
            <strong style={{ color: '#4985E2' }}>
                Ensure to enable/allow system location settings when prompted
            </strong>
          </div>
          <div style={{marginTop:'12px'}}>
            <Checkbox checked={rememberLocationChoice} onChange={() => changeCheckInPreference()} style={{fontWeight:500}}>Remember next time </Checkbox>
          </div>
      </Modal>
      <Modal
        visible={showCheckInModal && checkInInfo && checkInInfo.type === 'message'}
        footer={null}
        onCancel={() => setShowCheckInModal(false)}
      >
        <div style={{
          textAlign: 'center',
          padding: '50px 12px 25px',
          fontSize: '18px'
        }}>
          {
            <p>{checkInInfo.data}</p>
          }
        </div>
      </Modal>
      <Spin spinning={isCheckingIn} size="large" tip="Checking In...">
        {
        <Badge.Ribbon text={badgeRibbenData?.text} color={badgeRibbenData?.color} >
          <CodeWrapper/>
        </Badge.Ribbon>}
      </Spin>
    </>
  );
};

export default AppointmentCard;
