import React, {useState} from "react";
import {FileTextOutlined} from '@ant-design/icons';
import {Badge} from 'react-bootstrap';
import CRUDDataTable from "../../components/DataTable/CRUDDataTable";
import {Link} from "react-router-dom";
import {fetchFormsSummary} from "../../api/formSubmissions";
import FormSubmitModal from "../../components/Appointments/FormSubmitModal";
import {Button as AntdButton} from "antd";
import {updatePatientTaskStatus} from "../../api/patientTasks";

const Forms = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [activeSubmissionId, setActiveSubmissionId] = useState();
  const [designId, setDesignId] = useState();
  const [formStatus, setFormStatus] = useState(null);
  const [formName, setFormName] = useState();

  const columns = [
    {
      title: <strong>Name</strong>,
      dataIndex: "formDesign.name",
      key: "formDesign.name",
      sorter: true,
      render: (text, record, value) => {
        let statusBadge = null;

        if (record?.status === 'NEW') {
          statusBadge = <Badge variant="info">NEW</Badge>;
        }

        if (record?.status === 'DRAFT') {
          statusBadge = <Badge variant="secondary">DRAFT</Badge>;
        }

        if (record?.status === 'COMPLETE') {
          statusBadge = <Badge variant="success">COMPLETE</Badge>;
        }

        return (
          <>
            <FileTextOutlined/>
            <AntdButton type="link" className="font-weight-bold" onClick={() => {
              setActiveSubmissionId(record?.id)
              setDesignId(record?.formDesign.id)
              setFormName(record?.formDesign.name)
              setFormVisible(true)
              setFormStatus(record?.status)
            }}>
              <span style={{position: 'relative', top: '3px'}}>
                {record?.formDesign.name}
              </span>
            </AntdButton>&nbsp;{statusBadge}
          </>
        )
      }
    }
  ];

  return (
    <>
      <FormSubmitModal
        visible={formVisible}
        setVisible={setFormVisible}
        fullScreen={false}
        activeSubmissionId={activeSubmissionId}
        designId={designId}
        name={formName}
        formStatus={formStatus}
        // onSuccess={completeTask}
      />
      <CRUDDataTable
        fetchDataFunction={async (params) => {
          params = {
            ...params,
            sortOrder: 'id'
          }
          return fetchFormsSummary(params);
        }}
        entity="form"
        showTitle={false}
        columns={columns}
        highlightRow={(rec, i) => rec.highlight}
        button={null}
      />
    </>
  );
}
export default Forms;