import React from 'react';
import { Avatar } from 'antd';
import { message as notificationMessage } from 'helpers/notificationhelper';
import { Row, Col, Container } from 'react-bootstrap';
import { MailOutline } from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import {
    markNotificationAsReadById,   
} from 'redux/actions/notifications';
import parser from 'html-react-parser';
import {
    setNotificationsVisibility,
} from 'redux/actions/ui';
import { useHistory } from 'react-router';
import Moment from 'moment';
import { getUser } from 'api/users';
import {
    setCurrentUser,
    showAccountSettings
  } from 'redux/actions/currentUser';

import { STATUS_DID_NOT_ATTEND,STATUS_COMPLETE,STATUS_CANCELLED } from 'constants/appointment-constants';
import moment from 'moment';

const NotificationItem = ({
    index,
    id, 
    emailAddress, 
    phoneNumber, 
    subject, 
    message, 
    status,
    appointment = {},
    isMessageType = false,
    notificationType = '',
    formSubmission = {}
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const fetchCurrentUser = async () => {
        const res = await getUser();
        dispatch(setCurrentUser(res.data));
      };

    const newMessageHTML = message?.replaceAll(/<p>|<\/p>/g, '') || null;

    return (
        <div className={`Notification-Item ${status === 'UNREAD' &&  'Unread-Notification' }`} 
            onClick={() => {
                if (id) {
                    dispatch(markNotificationAsReadById([index]));
                    dispatch(setNotificationsVisibility(false));
                    
                    let past_status = [STATUS_CANCELLED,STATUS_COMPLETE,STATUS_DID_NOT_ATTEND];
                    let spanUrl = 'upcoming-appointments';
                    console.log('did i occured',appointment?.status);

                    const currentLocalTime = moment().startOf('day').local();
                    const appointmentLocalTime = moment.utc(appointment.appointmentDateTime).local();

                    // if(appointment?.status && past_status.includes(appointment?.status) || (currentLocalTime > appointmentLocalTime)) {
                    if(currentLocalTime > appointmentLocalTime) {
                        spanUrl = 'past-appointments';
                    } 

                    // const spanUrl = Moment(Moment(appointment.start).format('DD MMM YYYY HH:mm')).isBefore(Moment()) ? 'past-appointments' : 'upcoming-appointments';
                    // //const spanUrl = appointmentSpan === 'UPCOMING' ? 'upcoming-appointments' : 'past-appointments';
                    let notificationItemUrl 
                    if (notificationType === 'MESSAGE') {
                        notificationItemUrl = `/inbox/${id}`
                    } else if (notificationType === 'QUESTIONNAIRE') {
                        notificationItemUrl = `/${spanUrl}/${appointment?.id}?form-id=${formSubmission.id}&form-design-id=${formSubmission.formDesign.id}&name=${formSubmission.formDesign.name}&status=${formSubmission.status}`
                    } else if (notificationType === 'APPOINTMENT') {
                        notificationItemUrl = `/${spanUrl}/${id}`
                    } else {}

                    history.push(notificationItemUrl);
                } else {
                    if (notificationType === 'PATIENT') {
                        dispatch(markNotificationAsReadById([index]));
                        fetchCurrentUser();
                        dispatch(showAccountSettings(true));
                    } else {
                        notificationMessage.error('No id for this notification item.');
                    }
                }
            }}>
            <Container fluid>
                <Row>
                    <Col xs={2} md={2}>
                        <Avatar
                            size='large'
                            icon={<MailOutline />}
                            style={{
                                backgroundColor: '#74bbf8'
                            }}
                        />
                    </Col>
                    <Col xs={10} md={10}>
                        <h4 className='Message-Title'>{subject}</h4>
{/*
                        <h5><i><strong>From:</strong> {(phoneNumber ? phoneNumber : emailAddress)}</i></h5>
*/}
                        <h5 className='Message-Text'>{newMessageHTML && parser(newMessageHTML)}</h5>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NotificationItem;