import React, { useEffect, useState } from 'react';
import 'styles/Nav.scss';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button, Modal } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import NotificationList from 'components/Notifications/NotificationList';

import { toggleSideBarVisibility } from 'redux/actions/ui';
import { keycloak } from '../../../api/keycloak';
import { hideSettings } from 'api/settings';
import { SET_CURRENT_USER } from 'redux/actions/types/currentUser';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './../../../constants/index';
import SettingsModule from 'modules/SettingsModule';

import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { message as notificationMessage } from 'helpers/notificationhelper';
import {AUTH_ACTIONS} from 'redux/types/auth-types';
import {useAppSelector} from "../../../hooks/use-app-selector";
import {
  logout
} from 'api/logout';
import { setNotificationAsClear, setNotificationAsRead } from 'redux/actions/notifications';
import { getUser } from 'api/users';
import {
  setCurrentUser,
  showAccountSettings
} from 'redux/actions/currentUser';

function SlickTopMenu(props) {
  const dispatch = useDispatch();

  const { fullName, isSidebarVisible, toggleSideBarVisibility } = props;
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { confirm } = Modal;
  const currentUser = useSelector(state => state.currentUser.data);
  const tenantState = useSelector((state) => state.tenant);
  const [hideSettingsInMenu, setHideSettingsInMenu] = useState(false);
  const permissions = useAppSelector((state) => state.permissions.permissions);
  const showSettings = useSelector(state => state.currentUser.showAccountSettings);

  const { t } = useTranslation();
  useEffect(() => {
    // fetchHideSettings();
  }, []);

  useEffect(() => {
    if (showSettings) {
      setIsSettingsOpen(true);
    } else if (!showSettings && showSettings !== undefined) {
      setIsSettingsOpen(false);
    } else {}
  }, [showSettings]);

  const showLogoutModal = (e) => {
    confirm({
      title: 'Confirm',
      content: 'Are you sure you want to logout?',
      okText: 'Yes',
      cancelText: 'No',
      maskClosable: true,
      onOk: () => {
        message.loading(t('message.signing_out'), 0);
        if(Cookies.get(TOKEN_NAME)) {
          dispatch(setNotificationAsRead());
          dispatch(setNotificationAsClear());
        }
        logout({})
          .then(e => {
            if (e && e.status === 200) {
              message.success('You have successfully logged out from the App.');
            }
          })
          .catch(e => {
            if (e && e.status === 500) {
              notificationMessage.error("An error has occurred. Please try again later.")
            }
          });
      }
    });
  };

  const fetchHideSettings = async () => {
    const res = await hideSettings();
    const val = res && res.data ? res.data.value === 'true' : false;
    setHideSettingsInMenu(val);
  };

  const fetchCurrentUser = async () => {
    const res = await getUser();
    dispatch(setCurrentUser(res.data));
  };

  return (
    <div>
      <Navbar className='fivef-navbar' expand='lg' bg='light' variant='light'>
        <Navbar.Brand
          className='fivef-navbar-brand text-center'
          href='#home'
          style={{
            textTransform: 'uppercase',
            lineHeight: '15px',
          }}
        >
          {tenantState.branding.page.navbarHeaderTitle}
          <br />
          <small
            style={{
              color: `${tenantState.branding.theme.palette.primary.light}`,
              fontSize: '11px',
              fontWeight: '700',
              letterSpacing: '1px',
            }}
          >
            {currentUser
              ? `${currentUser?.firstName} ${currentUser?.lastName}`
              : `Patient!`}
          </small>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        <Button
          className='btn-toggle-mb-sidebar d-none d-sm-none'
          onClick={() => toggleSideBarVisibility()}
        >
          {isSidebarVisible ? (
            <MenuOpen fontSize='large' />
          ) : (
            <Menu fontSize='large' />
          )}
        </Button>
        <Nav.Link className='btn-toggle-mb-sidebar d-flex d-lg-none'>
          <NotificationList />
        </Nav.Link>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link onClick={() => toggleSideBarVisibility()}>
              {isSidebarVisible ? (
                <MenuOpen fontSize='large' />
              ) : (
                <Menu fontSize='large' />
              )}
            </Nav.Link>
          </Nav>
          <Nav className='ml-auto'>
            <Nav.Link className='d-none d-lg-flex'>
              <NotificationList />
            </Nav.Link>
            <NavDropdown
              className='fivef-user-dropdown'
              title={
                <PersonIcon fontSize='large'/>
              }
              id='collasible-nav-dropdown'
              style={{ width: '100%!important' }}
            >
              { permissions.Settings?.length > 0 ? (
                <NavDropdown.Item onClick={() => {
                  fetchCurrentUser();
                  dispatch(showAccountSettings(true));
                }}>
                  <SettingsIcon /> Account Settings
                </NavDropdown.Item>
              ) : null}
              <NavDropdown.Item onClick={showLogoutModal}>
                <ExitToApp /> Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <SettingsModule
        isOpen={isSettingsOpen}
        handleClose={() => dispatch(showAccountSettings(false))}
      />
    </div>
  );
}

export default connect(
  (state) => {
    return {
      isSidebarVisible: state.ui.isSidebarVisible,
    };
  },
  (dispatch) => {
    return {
      toggleSideBarVisibility: () => dispatch(toggleSideBarVisibility()),
    };
  }
)(SlickTopMenu);
