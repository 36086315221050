import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import SockJsClient from "react-stomp";
import { BASE_URL } from "api/config";

import { setNotificationAsClear, setNotificationsUnReadCount, setNotificationAsRead } from "redux/actions/notifications";
import { setFormSubmissionsIncompleteCount } from "redux/actions/formBuilder";
import { setTasksIncompleteCount } from "redux/actions/tasks";
import { setMessagesUnreadCount } from "redux/actions/messages";
import { fetchAppointmentsBySpan } from 'redux/actions/appointment';
import Cookies from "js-cookie";
import { TOKEN_NAME } from "../constants";
import {AUTH_ACTIONS} from 'redux/types/auth-types';
import { useAppSelector } from "../hooks/use-app-selector";
import moment from 'moment';

const WebSocket = React.memo(() => {
  let sockJSRef = useRef(null);
  const dispatch = useDispatch();
  const currentUser = useAppSelector(state => state.currentUser.data);
  const appointments = useAppSelector(state => state.appointment);

  useEffect(() => {
    if (sockJSRef) {
      sockJSRef.connect();
    }
  }, []);

   const  logout= () => {
     Cookies.remove(TOKEN_NAME);
     dispatch({
      type: AUTH_ACTIONS.REMOVE_TOKEN
    });
    if(Cookies.get(TOKEN_NAME)) {
      dispatch(setNotificationAsRead());
      dispatch(setNotificationAsClear());
    }
    void window.location.assign('/');
}

  const toJsonString = (res) => {
    return JSON.parse(res?.replaceAll('=', ':')?.replace('patientId', '"patientId"')?.replace('count', '"count"'));
  }

  const sockets = [
    {
      url: `${BASE_URL}/ws/notifications-unread-count`,
      topics: ["/topic/notifications"],
      callback: (res) => {
        let response = toJsonString(res);
        (parseInt(currentUser?.id) === response?.patientId) && dispatch(setNotificationsUnReadCount(response?.count));
      },
    },
    {
      url: `${BASE_URL}/ws/messages-unread-count`,
      topics: ["/topic/messages"],
      callback: (res) => {
        let response = toJsonString(res);
        (parseInt(currentUser?.id) === response?.patientId) && dispatch(setMessagesUnreadCount(response?.count));
      },
    },
    {
      url: `${BASE_URL}/ws/form-submissions-incomplete-count`,
      topics: ["/topic/forms"],
      callback: (response) => {
        dispatch(setFormSubmissionsIncompleteCount(response));
      },
    },
    {
      url: `${BASE_URL}/ws/patient-tasks-incomplete-count`,
      topics: ["/topic/tasks"],
      callback: (response) => {
        dispatch(setTasksIncompleteCount(response));
      },
    },
    {
      url: `${BASE_URL}/ws/patient-auto-logout`,
      topics: ["/topic/patient-logout"],
      callback: (response) => {
        (currentUser?.mobileNumber.substr(-9) === response.toString().substr(-9)) && dispatch(logout(response))
      },
    },
    {
      url: `${BASE_URL}/ws/update-appointment`,
      topics: ["/topic/update-appointment"],
      callback: (response) => {
        (currentUser?.id === response?.patientId) && dispatch(fetchAppointmentsBySpan(appointments.span, {
          length: appointments.length,
          page: appointments.currentPageNumber,
          sortDirection: appointments.sortDirection,
          sortColumn: appointments.sortColumn,
          currentDate: moment.utc().format("YYYY-MM-DD HH:mm:ss").toString()
        }));
      },
    },
    {
      url: `${BASE_URL}/ws/create-appointment`,
      topics: ["/topic/create-appointment"],
      callback: (response) => {
        (currentUser?.id === response?.patientId) && dispatch(fetchAppointmentsBySpan(appointments.span, {
          length: appointments.length,
          page: appointments.currentPageNumber,
          sortDirection: appointments.sortDirection,
          sortColumn: appointments.sortColumn,
          currentDate: moment.utc().format("YYYY-MM-DD HH:mm:ss").toString()
        }));
      },
    },
  ];
  return (
    <>
      {sockets.map((socket, index) => (
        <div key={index}>
          <SockJsClient
            url={socket.url}
            topics={socket.topics}
            onMessage={socket.callback}
            onConnect={() => {console.log('connected')}}
            ref={(client) => (sockJSRef = client)}
          />
        </div>
      ))}
    </>
  );
});

export default WebSocket;