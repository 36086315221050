import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card , Badge} from 'antd';
import { FcMindMap, FcOvertime} from 'react-icons/fc';
import { MdInfo } from 'react-icons/md';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function AppointmentInfo({ appointment }) {
  const [items, setItems] = useState([]);
  const [ribbonColor,setRibbonColor] = useState('');

  const { t } = useTranslation();

  useEffect(() => {

    let appointmentStatus = appointment?.labelStatus ?? '';
    appointmentStatus = appointmentStatus.toUpperCase();
    setItems([
      {
        heading: t('label.date_time'),
        // detailText: <>{moment(appointment.appointmentDate).format("LL")} {moment().set('hour', 8).format('h A')}</>,
        detailText: moment.utc(appointment.appointmentDateTime).local().format(
          'DD MMM YYYY hh:mm a'
        ),
        icon: <FcOvertime style={{ width: 48, height: 48 }} />,
        status:appointmentStatus ?? ''
      },
      {
        heading: t('label.clinic'),
        detailText: appointment.locationName,
        icon: <FcMindMap style={{ width: 48, height: 48 }} />,
        status:''
      },
      {
        heading: t('label.instruction'),
        detailText: appointment?.checkInInstruction,
        icon: <MdInfo style={{ color: '#fff', width: 48, height: 48 }} />,
        status:''
      },
      // {
      //   heading: t('label.phone_number'),
      //   detailText: appointment.locationContactNumber ? appointment.locationContactNumber.slice(0, 11)+'...' : '07 3232 7594',
      //   icon: <MdPhone style={{ color: '#fff', width: 48, height: 48 }} />,
      // },
    ]);

    switch (appointmentStatus) {
      case 'COMPLETE':
      case 'COMPLETED':
        setRibbonColor('royalblue');
        break;
      case 'CONFIRMED':
        setRibbonColor('green');
        break;
      case 'UNCONFIRMED':
        setRibbonColor('red');
        break;
      case 'CANCELLED':
        setRibbonColor('red');
        break;
      case 'DID NOT ATTEND':
        setRibbonColor('volcano');
        break;
      case 'SCHEDULED':
        setRibbonColor('gold');
        break;
      case 'IN PROGRESS':
        setRibbonColor('purple');
        break;
      default:
        break;
    }

  }, [appointment]);

  const CardTile = (props) => {
    let item = props.item;
    let i = props.index;
    return <Card key={i} className='Card-Gradient-Primary'>
             <Container fluid>
               <Row>
                  <Col xs={9} md={10} lg={10}>
                    <h4 className='Appointment-Detail-Title'>
                      <strong className='text-uppercase'>
                        {item.heading}
                      </strong>
                    </h4>
                    <h4 className={'Appointment-Detail-Text'}>
                      {item.detailText}
                    </h4>
                    
                  </Col>
                  <Col xs={3} md={2} lg={2}>
                    {item.icon}
                  </Col>
                </Row>
                {i === 2 && appointment.displayBarcode && appointment.barcode && <div className='d-flex justify-content-end w-100 mb-4'>
                  <img className='View-Details-Qr Qr-Code' src={`data:image/png;base64,${appointment.barcode}`} />
                </div>}
                {i === 0 && appointment.ticketNumber && <div className="Appointment-Ticket-Number View-Details-Ticket">
                  <div style={{fontSize: '12px'}}>Ticket Number</div>
                  <div style={{fontSize: '14px', fontWeight: 'bold'}}>{appointment.ticketNumber}</div>
                </div>}
              </Container>
            </Card>
  }

  return (
    <>
      {/* <Card className="Appointment-Detail-Card Card-Grids Appointment-Card"> */}
      <Container className='Appointment-Info-Container' fluid>
        <Row>
          {items &&
            items.length > 0 &&
            items.map((item, i) => (
              <Col key={i} xs={12} lg={6} xl={4}>
                 {item.status ? 
                  <Badge.Ribbon text={item.status} color={ribbonColor}>
                    <CardTile item={item} index={i}/>
                  </Badge.Ribbon>
                  :
                    <CardTile item={item} index={i}/>
                  }
              </Col>
            ))}
        </Row>
      </Container>
      {/* </Card> */}
    </>
  );
}

export default AppointmentInfo;
